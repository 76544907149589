<script setup lang="ts">
import { onMounted, ref } from "vue";
import AddQuestionModal from "./components/AddCompanyQuestion.vue";
import CompanyQuestionCardComponent from "./manage-employee/CompanyQuestionCardComponent.vue";
import {
  QuestionInterface,
  QuestionTypes,
} from "../../interface/QuestionInterface";
import { appContainer } from "../../container";
import { useRoute } from "vue-router";
import { LoadingService } from "../../services/loadingService";
import { PagingInterface } from "../../interface/PaginationInterface";
import { ManageQuestionService } from "../../services/manageQuestionService";
import { CompanyQuestionFrozenStatus } from "../../stores/CompanyQuestionStore";
import FrozenConfirmationModal from "./components/FreezeConfirmationModal.vue";
import { ToastService } from "../../services/toastService";

const route = useRoute();
const loaderService = appContainer.resolve(LoadingService);
const manageQuestionService = appContainer.resolve(ManageQuestionService);

const isLoading = ref(false);
const showAddModal = ref(false);
const questions = ref<QuestionInterface[]>([]);
const paginatedData = ref<PagingInterface<QuestionInterface>>();
const questionStatus = CompanyQuestionFrozenStatus();
const showFreezeModal = ref(false);
const toastService = appContainer.resolve(ToastService);

onMounted(async () => {
  fetchQuestions({ page: 1, offset: 10 });
  await questionStatus.getQuestionStatus(Number(route.params.id));
});

async function addQuestion() {
  showAddModal.value = false;
  fetchQuestions({ page: 1, offset: 10 });
}

async function fetchQuestions(pageConfig: { page: number; offset: number }) {
  const loader = await loaderService.show();

  manageQuestionService
    .getQuestion(
      pageConfig,
      QuestionTypes.Company,
      Number(route.params.id),
      true,
    )
    .then((paginatedResult) => {
      paginatedData.value = paginatedResult;
      questions.value = paginatedResult.items;
    })
    .finally(() => {
      loader.hide();
      isLoading.value = false;
    });
}

async function freezeCompanyQues() {
  showFreezeModal.value = false;
}

async function checkFrozenOnClick() {
  if (!questionStatus.isFrozen) {
    showAddModal.value = true;
  } else {
    toastService.error("Unfreeze Questions to Add a Question");
    return false;
  }
}
</script>
<template>
  <div class="d-flex w-100 justify-content-between flex-wrap mb-4 gap-10">
    <p class="fw-medium fs-5 m-0">Company Questions</p>
    <div class="btn-wrapper d-flex align-items-center flex-wrap gap-10">
      <button
        class="btn btn-primary btn-sm text-white"
        @click="checkFrozenOnClick"
      >
        Add Question
      </button>
      <button
        class="btn btn-primary btn-sm text-white"
        @click="showFreezeModal = true"
      >
        <span v-if="questionStatus.isFrozen">Unfreeze</span
        ><span v-else>Freeze</span> Questions
      </button>
    </div>
  </div>

  <template v-if="paginatedData?.items">
    <div class="all-question d-flex flex-column">
      <CompanyQuestionCardComponent
        v-for="(question, index) in questions"
        :key="question.id"
        :question="question"
        :index="
          paginatedData
            ? (paginatedData.page - 1) * paginatedData.offset + (index + 1)
            : 1
        "
        @delete="fetchQuestions({ page: 1, offset: 10 })"
        @edit="fetchQuestions({ page: 1, offset: 10 })"
      />
    </div>
  </template>
  <template v-if="isLoading && paginatedData?.items">
    <p class="text-center mt-5">No Questions to view, Please add some</p>
  </template>

  <AddQuestionModal
    v-if="showAddModal"
    :companyId="+Number(route.params.id)"
    @close="showAddModal = false"
    @add="addQuestion"
  />

  <FrozenConfirmationModal
    v-if="showFreezeModal"
    :status="questionStatus.isFrozen ? 'unfreeze' : 'freeze'"
    :id="Number(route.params.id)"
    @close="showFreezeModal = false"
    @yes="freezeCompanyQues"
  />
</template>
<style lang="scss" scoped>
.gap-10 {
  gap: 10px;
}
</style>
