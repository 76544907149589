<script setup lang="ts">
import { ref } from "vue";
import { ErrorResponse } from "../../interface/ErrorInterface";
import { appContainer } from "../../container";
import { LoadingService } from "../../services/loadingService";
import { ToastService } from "../../services/toastService";
import router from "../../routes";
import { AxiosError } from "axios";
import { useIsFrozenStore } from "../../stores/IsQuestionFrozenStore";
import ValidationErrorComponent from "../../components/ValidationErrorComponent.vue";

const props = defineProps<{
  status: string;
}>();
const emit = defineEmits(["close", "yes"]);

const validationErrors = ref<ErrorResponse>();
const loadingService = appContainer.resolve(LoadingService);
const toastService = appContainer.resolve(ToastService);

const questionFrozenStore = useIsFrozenStore();

async function toggleFrozenStatus() {
  const loader = await loadingService.show();
  questionFrozenStore
    .toggleFrozenStatus(!questionFrozenStore.isFrozen)
    .then(() => {
      router.push("/manage-questions");
      toastService.success(
        `Questions ${questionFrozenStore.isFrozen ? "Frozen" : "Unfrozen"} Successfully`,
      );
      emit("yes");
    })
    .catch((err) => {
      const error = err as AxiosError;
      const errorResponse = error.response?.data as ErrorResponse;
      if (errorResponse?.errors) {
        const flattenedErrors = Object.values(errorResponse.errors).flat();
        toastService.error(flattenedErrors.join("<br>"));
      } else {
        toastService.error(
          `Unable to ${questionFrozenStore.isFrozen ? "Unfreeze" : "Freeze"} Questions`,
        );
      }
    })
    .finally(() => {
      loader.hide();
    });
}
</script>

<template>
  <div class="modal-backdrop">
    <div class="modal" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <ValidationErrorComponent
              v-if="validationErrors"
              :validations="validationErrors"
            ></ValidationErrorComponent>
            <h5 class="text-center my-4 ff-montserrat">
              Are you sure you want to {{ props.status }} the questions?
            </h5>
            <div class="d-flex align-items-center justify-content-evenly p-4">
              <button
                type="button"
                class="btn btn-outline-danger w-25"
                @click="emit('close')"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-secondary text-white w-25"
                @click="toggleFrozenStatus"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
button {
  &:hover {
    color: #fff;
  }
}
</style>
