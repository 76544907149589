<script lang="ts" setup>
import { onBeforeMount, ref } from "vue";
import { useRoute } from "vue-router";
import { appContainer } from "../../container";
import { UserService } from "../../services/userService";
import { LoadingService } from "../../services/loadingService";
import { InvitedUserInterface, Role } from "../../interface/UserInterface";
import { ToastService } from "../../services/toastService";
import router from "../../routes";
import { ActiveRoleStore } from "../../stores/ActiveRoleStore";
import UserCardListing from "../admin/UserCardListing.vue";
import AddRaterModal from "../admin/modal/AddUserModal.vue";
import { CurrentInvitationStore } from "../../stores/CurrentInvtationStore";

const route = useRoute();
const userService = appContainer.resolve(UserService);
const loadingService = appContainer.resolve(LoadingService);
const toastService = appContainer.resolve(ToastService);
const admins = ref<InvitedUserInterface[]>([]);
const participants = ref<InvitedUserInterface[]>([]);
const raters = ref<InvitedUserInterface[]>([]);

const currentInvitation = ref<InvitedUserInterface>();
const useActiveStore = ActiveRoleStore();
const showAddModal = ref(false);
const currentInvitationStore = CurrentInvitationStore();

onBeforeMount(async () => {
  if (useActiveStore.activeRole?.role_name != "super_admin") {
    currentInvitationStore.getCurrentInvitation();
  }
  await getCurrentInvitation();
  if (currentInvitation.value) {
    switch (currentInvitation.value.invitation_type) {
      case Role.Admin:
        await getRelatedUsers(
          currentInvitation.value.invited_user?.id,
          undefined,
          Role.Participants,
        ).then((result) => {
          participants.value = result;
        });
        break;
      case Role.Participants:
        await getRelatedUsers(
          undefined,
          currentInvitation.value.invited_user?.id,
          currentInvitation.value.invitation_type,
        ).then((result) => {
          admins.value = result;
        });
        await getRelatedUsers(
          currentInvitation.value.invited_user.id,
          undefined,
          Role.Raters,
        ).then((result) => {
          raters.value = result;
        });
        break;
      case Role.Raters:
        await getRelatedUsers(
          undefined,
          currentInvitation.value.invited_user?.id,
          currentInvitation.value.invitation_type,
        ).then((result) => {
          participants.value = result;
        });
        break;
      default:
        toastService.error("Unable to get User details");
        router.push("/manage-users");
        break;
    }
  }
});

async function getRelatedUsers(
  invited_for: number | undefined = undefined,
  invited_user: number | undefined = undefined,
  invitation_type: string,
  pageConfig: { page?: number; offset?: number } = {
    page: 1,
    offset: 8,
  },
) {
  return userService
    .getInvitedUser(
      {
        invitationType: invitation_type,
        all: true,
        invited_user,
        invited_for,
      },
      pageConfig,
    )
    .then((result) => result.items);
}

async function getCurrentInvitation() {
  const loader = await loadingService.show();
  return userService
    .getCurrentInvitation(Number(route.params.id))
    .then((result) => {
      currentInvitation.value = result;
    })
    .finally(() => loader.hide());
}

async function getUpdatedRaterList() {
  getRelatedUsers(
    currentInvitation.value!.invited_user.id,
    undefined,
    Role.Raters,
  ).then((result) => {
    raters.value = result;
  });
}

async function addUser() {
  showAddModal.value = false;
  getUpdatedRaterList();
}
</script>
<template>
  <div class="breadcrumbs" v-if="!currentInvitationStore.currentInvitation">
    <router-link to="/manage-users" class="text-decoration-none"
      >Manage Users</router-link
    >
    / {{ currentInvitation?.invited_user.first_name }}
    {{ currentInvitation?.invited_user.last_name }}
  </div>
  <template
    v-if="currentInvitationStore.currentInvitation?.invitation_type === 'admin'"
  >
    <div class="breadcrumbs">
      <router-link to="/manage-participants" class="text-decoration-none"
        >Manage Participant</router-link
      >
      / {{ currentInvitation?.invited_user.first_name }}
      {{ currentInvitation?.invited_user.last_name }}
    </div>
  </template>
  <template
    v-if="
      currentInvitationStore.currentInvitation?.invitation_type ===
      'participant'
    "
  >
    <div class="breadcrumbs">
      <router-link to="/manage-raters" class="text-decoration-none"
        >Manage Rater</router-link
      >
      / {{ currentInvitation?.invited_user.first_name }}
      {{ currentInvitation?.invited_user.last_name }}
    </div>
  </template>
  <div class="invited-user-info p-4 mb-5 mt-4" v-if="currentInvitation">
    <p class="fw-medium name mb-0 text-capitalize">
      {{ currentInvitation.invited_user.first_name }}
      {{ currentInvitation.invited_user.last_name }}
    </p>
    <p class="email mb-2">{{ currentInvitation.invited_user.email }}</p>
    <span class="d-flex align-items-center role"
      >User Role:
      <strong class="text-capitalize ms-2">
        {{ currentInvitation.invitation_type }}</strong
      ></span
    >
  </div>
  <template v-if="currentInvitation?.invitation_type === Role.Admin">
    <h5 class="ff-montserrat fw-bold">Participant(s)</h5>
    <div class="user-list d-flex flex-column">
      <template v-for="user in participants" :key="user.id">
        <div class="user-card p-3 border-bottom">
          <p class="fw-medium name mb-0 text-capitalize">
            {{ user.invited_user.first_name }} {{ user.invited_user.last_name }}
          </p>
          <span class="email">{{ user.invited_user.email }}</span>
        </div>
      </template>
    </div>
  </template>
  <template v-if="currentInvitation?.invitation_type === Role.Participants">
    <h5 class="ff-montserrat fw-bold">Admin(s)</h5>
    <div class="user-list mb-5">
      <template v-for="user in admins" :key="user.id">
        <div class="user-card p-3 border-bottom">
          <p class="fw-medium name mb-0 text-capitalize">
            {{ user.invited_for_user?.first_name }}
            {{ user.invited_for_user?.last_name }}
          </p>
          <span class="email">{{ user.invited_for_user?.email }}</span>
        </div></template
      >
    </div>

    <template v-if="useActiveStore.activeRole?.role_name === 'admin'">
      <div class="d-flex w-100 justify-content-between flex-wrap mb-4 gap-10">
        <h5 class="ff-montserrat fw-bold">Rater(s)</h5>
        <div class="btn-wrapper d-flex align-items-center flex-wrap">
          <button
            class="btn btn-primary btn-sm text-white"
            @click="showAddModal = true"
          >
            Add Rater
          </button>
        </div>
      </div>
      <div class="user-list mb-5">
        <UserCardListing
          v-for="rater in raters"
          :key="rater.id"
          :user="rater"
          :currentRole="Role.Raters"
          @invite="getUpdatedRaterList"
          @delete="getUpdatedRaterList"
          @edit="getUpdatedRaterList"
          :showDetail="false"
        />
      </div>
    </template>
    <template v-else>
      <h5 class="ff-montserrat fw-bold">Rater(s)</h5>
      <div class="user-list mb-5">
        <template v-for="user in raters" :key="user.id">
          <div class="user-card p-3 border-bottom">
            <p class="fw-medium name mb-0 text-capitalize">
              {{ user.invited_user.first_name }}
              {{ user.invited_user.last_name }}
            </p>
            <span class="email">{{ user.invited_user.email }}</span>
          </div></template
        >
      </div>
    </template>
  </template>
  <template v-if="currentInvitation?.invitation_type === Role.Raters">
    <h5 class="ff-montserrat fw-bold">Participant(s)</h5>
    <div class="user-list mb-5">
      <template v-for="user in participants" :key="user.id">
        <div class="user-card p-3 border-bottom">
          <p class="fw-medium name mb-0 text-capitalize">
            {{ user.invited_for_user?.first_name }}
            {{ user.invited_for_user?.last_name }}
          </p>
          <span class="email">{{ user.invited_for_user?.email }}</span>
        </div></template
      >
    </div>
  </template>

  <AddRaterModal
    v-if="showAddModal"
    @add="addUser"
    @close="showAddModal = false"
    :type="Role.Raters"
    :for="currentInvitation?.invited_user.id"
  />
</template>

<style lang="scss" scoped>
@import "../../theme/variables";

.invited-user-info {
  background: rgb($blue, 0.07);
  border-radius: 4px;
}
.email,
.role {
  font-size: 14px;
}

.user-list {
  gap: 10px;
}
</style>
