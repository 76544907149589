<script setup lang="ts">
import { AxiosError } from "axios";
import { appContainer } from "../../container";
import { ErrorResponse } from "../../interface/ErrorInterface";
import { LoadingService } from "../../services/loadingService";
import { ManageQuestionService } from "../../services/manageQuestionService";
import { ToastService } from "../../services/toastService";
import { ref } from "vue";
import ValidationErrorComponent from "../../components/ValidationErrorComponent.vue";
import { CompanyService } from "../../services/manageCompanyService";

const props = defineProps<{
  id: number;
  companyId?: number | null;
}>();
const emit = defineEmits(["close", "delete"]);

const validationErrors = ref<ErrorResponse>();
const manageQuestionService = appContainer.resolve(ManageQuestionService);
const loadingService = appContainer.resolve(LoadingService);
const toastService = appContainer.resolve(ToastService);
const companyService = appContainer.resolve(CompanyService);

async function deleteQuestion() {
  const loader = await loadingService.show();
  if (props.companyId) {
    companyService
      .deleteCompanyQuestion(props.id, props.companyId)
      .then(() => {
        toastService.success("Question Deleted Successfully");
        emit("delete");
      })
      .catch((err) => {
        const error = err as AxiosError;
        const errorResponse = error.response?.data as ErrorResponse;
        validationErrors.value = errorResponse;
      })
      .finally(() => loader.hide());
    return;
  }
  manageQuestionService
    .deleteQuestion(props.id)
    .then(() => {
      toastService.success("Question Deleted Successfully");
      emit("delete");
    })
    .catch((err) => {
      const error = err as AxiosError;
      const errorResponse = error.response?.data as ErrorResponse;
      validationErrors.value = errorResponse;
    })
    .finally(() => loader.hide());
}
</script>

<template>
  <div class="modal-backdrop">
    <div class="modal" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <ValidationErrorComponent
              v-if="validationErrors"
              :validations="validationErrors"
            ></ValidationErrorComponent>
            <h5 class="text-center my-4 ff-montserrat">
              Do you want to delete this Question?
            </h5>
            <div class="d-flex align-items-center justify-content-evenly p-4">
              <button
                type="button"
                class="btn btn-outline-primary"
                @click="emit('close')"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-danger text-white"
                @click="deleteQuestion"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
button {
  &:hover {
    color: #fff;
  }
}
</style>
