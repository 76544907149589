import { NavigationGuardWithThis } from "vue-router";

import { CurrentInvitationStore } from "../stores/CurrentInvtationStore";

export const FeedbackSubmittedGaurd: NavigationGuardWithThis<
  undefined
> = async () => {
  const currentInvitatonStore = CurrentInvitationStore();
  await currentInvitatonStore.getCurrentInvitation();

  return currentInvitatonStore.currentInvitation?.meta.feedback_done
    ? "/feedback-submitted"
    : true;
};
