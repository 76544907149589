import { injectable } from "inversify";
import { useLoading } from "vue-loading-overlay";
import type {
  PluginApi,
  ActiveLoader,
  Props,
  Slots,
} from "vue-loading-overlay";

export interface LoadingState {
  isVisible: boolean;
  hide(): Promise<boolean>;
  wrappedInstance: ActiveLoader;
}

@injectable()
export class LoadingService {
  public loader!: PluginApi;

  /**
   * Initializes the loading instance
   * @param handler
   */
  public init(): void {
    this.loader = useLoading({
      loader: "dots",
      color: "#4A84DD",
      backgroundColor: "#000",
      opacity: 0,
    });
  }
  constructor() {
    this.init();
  }

  protected createLoadingState(): LoadingState {
    return { isVisible: false } as LoadingState;
  }

  /**
   * Shows loader
   * @param props
   * @param slots
   */
  public async show(props?: Props, slots?: Slots): Promise<LoadingState> {
    const state = this.createLoadingState();
    const activeLoader = this.loader.show(props, slots);
    state.hide = async () => {
      activeLoader.hide();
      return true;
    };
    state.wrappedInstance = activeLoader;
    return state;
  }

  /**
   * Hides loader
   * @param state
   */
  public hide(state: LoadingState): Promise<boolean> {
    return state.hide();
  }
}
