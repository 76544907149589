<script setup lang="ts">
import { onMounted } from "vue";
import { appContainer } from "../../container";
import { AuthService } from "../../services/authService";
import { CurrentInvitationStore } from "../../stores/CurrentInvtationStore";

const authService = appContainer.resolve(AuthService);
const currentInvitationStore = CurrentInvitationStore();

onMounted(() => {
  currentInvitationStore.getCurrentInvitation();
});
</script>

<template>
  <div class="d-flex align-items-center justify-content-center w-100">
    <div class="flex-column flex-center">
      <div class="flex-center"></div>
      <h1 class="ff-montserrat fw-semibold fs-6">
        Assessment Submitted
        <span class="text-secondary">Successfully!</span>
      </h1>
      <h3 class="ff-montserrat mb-4 text-body-tertiary">
        Thank you for submitting assessment for
        <strong>
          {{
            currentInvitationStore.currentInvitation?.invited_for_user
              ?.first_name
          }}
          {{
            currentInvitationStore.currentInvitation?.invited_for_user
              ?.last_name
          }}</strong
        >.
      </h3>
      <button
        class="btn btn-primary text-white btn-sm"
        @click="authService.logout()"
      >
        Logout
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">
h3 {
  font-size: 14px;
}
button {
  padding: 5px 30px;
}
</style>
