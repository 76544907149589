<script setup lang="ts">
import { ref } from "vue";
import { appContainer } from "../../../container";
import { ErrorResponse } from "../../../interface/ErrorInterface";
import { InvitedUserInterface, Role } from "../../../interface/UserInterface";
import { LoadingService } from "../../../services/loadingService";
import { CompanyService } from "../../../services/manageCompanyService";
import { ToastService } from "../../../services/toastService";
import { UserService } from "../../../services/userService";
import { AxiosError } from "axios";
import ValidationErrorComponent from "../../../components/ValidationErrorComponent.vue";

const props = defineProps<{
  invitation: InvitedUserInterface;
}>();
const emit = defineEmits(["close", "invite"]);
const userService = appContainer.resolve(UserService);
const companyService = appContainer.resolve(CompanyService);

const loadingService = appContainer.resolve(LoadingService);
const toastService = appContainer.resolve(ToastService);
const validationErrors = ref<ErrorResponse>();

async function handleInviteSend() {
  const loader = await loadingService.show();
  if (props.invitation.company_id) {
    companyService
      .sendInvitation(props.invitation.company_id, props.invitation.id)
      .then(() => {
        toastService.success("Invitation Sent Successfully");
        emit("invite");
      })
      .catch(() => {
        toastService.error("Unable to Send Invitation");
      })
      .finally(() => loader.hide());
  } else {
    userService
      .sendInvite(props.invitation.id)
      .then(() => {
        toastService.success("Invitation Sent Successfully");
        emit("invite");
      })
      .catch((err) => {
        const error = err as AxiosError;
        const errorResponse = error.response?.data as ErrorResponse;
        if (errorResponse?.errors) {
          const flattenedErrors = Object.values(errorResponse.errors).flat();
          toastService.error(flattenedErrors.join("<br>"));
        } else {
          toastService.error(`Unable to Send Invitation`);
        }
      })
      .finally(() => loader.hide());
  }
}
</script>

<template>
  <div class="modal-backdrop">
    <div class="modal" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <ValidationErrorComponent
              v-if="validationErrors"
              :validations="validationErrors"
            ></ValidationErrorComponent>
            <p class="text-center my-4">
              Are you sure you want to send an invite to
              <strong class="text-capitalize">
                {{ invitation.invited_user.first_name }}
                {{ invitation.invited_user.last_name }}
              </strong>
              {{ invitation.invited_user.email }}
              as
              {{ invitation.invitation_type === Role.Admin ? "an" : "a" }}
              <strong class="text-capitalize">
                {{ invitation.invitation_type }}</strong
              >
              <span
                v-if="
                  invitation.invitation_type === Role.Participants ||
                  invitation.invitation_type === Role.Raters
                "
              >
                for
                {{ invitation.invited_for_user?.first_name }}
                {{ invitation.invited_for_user?.last_name }} </span
              >?
            </p>

            <div class="d-flex align-items-center justify-content-evenly p-4">
              <button
                type="button"
                class="btn btn-outline-danger btn-sm"
                @click="emit('close')"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-primary btn-sm text-white"
                @click="handleInviteSend"
              >
                Invite
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
button {
  &:hover {
    color: #fff;
  }
}
</style>
