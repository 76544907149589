<script setup lang="ts">
import { ref, onMounted } from "vue";
import { CompanyInterface } from "../../../interface/CompanyInterface";
import { appContainer } from "../../../container";
import { LoadingService } from "../../../services/loadingService";
import { CompanyService } from "../../../services/manageCompanyService";
import { ToastService } from "../../../services/toastService";
import { ErrorResponse } from "../../../interface/ErrorInterface";
import { AxiosError } from "axios";
import ValidationErrorComponent from "../../../components/ValidationErrorComponent.vue";

const props = defineProps<{
  company: CompanyInterface | null;
  token: string;
}>();

const companyService = appContainer.resolve(CompanyService);
const loadingService = appContainer.resolve(LoadingService);
const toastService = appContainer.resolve(ToastService);

const uploadImage = ref<string>("");
const logo = ref();
const validationErrors = ref<ErrorResponse>();

const companyData = ref({
  name: "",
  representative_email: "",
  description: "",
});

const errors = ref({
  name: "",
  representative_email: "",
  logo: "",
  description: "",
});

onMounted(() => {
  if (props.company) {
    companyData.value = {
      name: props.company.name || "",
      representative_email: props.company.representative_email || "",
      description: props.company.description || "",
    };
    if (props.company.logo_file) {
      uploadImage.value = `${props.company.logo_file}?token=${props.token}`;
    }
  }
});

const handleFileUpload = (e: Event) => {
  const target = e.target as HTMLInputElement;
  if (target.files && target.files.length > 0) {
    const file = target.files[0];
    if (file.size > 2 * 1024 * 1024) {
      errors.value.logo = "File size should not exceed 2MB.";
      logo.value = null;
      uploadImage.value = "";
    } else {
      errors.value.logo = "";
      logo.value = file;
      uploadImage.value = URL.createObjectURL(file);
    }
  }
};

const removeImage = () => {
  uploadImage.value = "";
  logo.value = null;
};

const validateForm = () => {
  let valid = true;

  if (!companyData.value.name) {
    errors.value.name = "Name is required.";
    valid = false;
  } else {
    errors.value.name = "";
  }

  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!companyData.value.representative_email) {
    errors.value.representative_email = "Email is required.";
    valid = false;
  } else if (!emailPattern.test(companyData.value.representative_email)) {
    errors.value.representative_email = "Invalid email format.";
    valid = false;
  } else {
    errors.value.representative_email = "";
  }
  if (!logo.value && !uploadImage.value) {
    errors.value.logo = "Please upload a logo.  ";
    valid = false;
  }
  if (!companyData.value.description) {
    errors.value.description = "Introduction is requried.";
    valid = false;
  }

  return valid;
};

const editCompany = async () => {
  if (validateForm()) {
    const loader = await loadingService.show();
    companyService
      .updateCompany(
        companyData.value as CompanyInterface,
        logo.value,
        props.company!.id,
      )
      .then(() => {
        toastService.success("Company Updated Successfully");
        emit("edit");
      })
      .catch((err) => {
        const error = err as AxiosError;
        const errorResponse = error.response?.data as ErrorResponse;
        validationErrors.value = errorResponse;
      })
      .finally(() => loader.hide());
  }
};

const emit = defineEmits(["close", "edit"]);
</script>

<template>
  <div class="modal-backdrop">
    <div class="modal fade show" id="edit-modal">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <p class="modal-title fw-bold">Edit Company</p>
            <button
              type="button"
              class="btn-close"
              @click="$emit('close')"
            ></button>
          </div>
          <ValidationErrorComponent
            v-if="validationErrors"
            :validations="validationErrors"
          ></ValidationErrorComponent>
          <form class="w-100 overflow-y-auto" @submit.prevent="editCompany">
            <div class="modal-body">
              <div class="mb-3">
                <label class="form-label fw-bold">Name</label>
                <input
                  type="text"
                  v-model="companyData.name"
                  placeholder="Name"
                  class="form-control"
                  maxlength="80"
                />
                <div v-if="errors.name" class="text-danger error mt-2">
                  {{ errors.name }}
                </div>
              </div>
              <div class="mb-3">
                <label class="form-label fw-bold">Representative Email</label>
                <input
                  type="email"
                  maxlength="50"
                  v-model="companyData.representative_email"
                  placeholder="Email"
                  class="form-control"
                />
                <div
                  v-if="errors.representative_email"
                  class="text-danger error mt-2"
                >
                  {{ errors.representative_email }}
                </div>
              </div>
              <div class="mb-3">
                <label class="form-label fw-bold">Introduction</label>
                <textarea
                  v-model="companyData.description"
                  class="form-control"
                  rows="5"
                  data-cy=""
                  maxlength="500"
                ></textarea>
                <div v-if="errors.description" class="text-danger error mt-2">
                  {{ errors.description }}
                </div>
              </div>
              <div class="mb-3">
                <label class="form-label fw-bold">Logo</label>
                <div
                  class="d-flex w-100 align-items-start justify-content-between"
                >
                  <div
                    class="fileUpload text-center btn btn-primary btn-sm text-white position-relative"
                  >
                    <input
                      type="file"
                      @change="handleFileUpload"
                      accept="image/png, image/jpg, image/jpeg"
                      class="upload position-absolute top-0 end-0 bottom-0 start-0"
                    />
                    <span>Upload Image <i class="bi bi-upload ms-2"></i></span>
                  </div>
                  <div
                    class="img-uploaded rounded position-relative ms-2 mb-2"
                    v-if="uploadImage"
                  >
                    <button
                      @click="removeImage"
                      class="btn-remove-img rounded-pill text-white d-flex align-items-center justify-content-center p-0 border-0 position-absolute"
                    >
                      <i class="bi bi-x"></i>
                    </button>
                    <img
                      :src="uploadImage"
                      alt="Uploaded Image"
                      class="w-100 h-100 object-fit-contain border rounded"
                    />
                  </div>
                </div>
                <div v-if="errors.logo" class="text-danger error mt-2">
                  {{ errors.logo }}
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="submit"
                class="btn btn-primary btn-sm mx-auto text-white"
              >
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.fileUpload {
  width: 175px;
}
.fileUpload input.upload {
  opacity: 0;
}
input[type="file"] {
  position: fixed;
  right: 100%;
  bottom: 100%;
}
.img-uploaded {
  width: 100px;
  height: 100px;
}
.btn-remove-img {
  width: 18px;
  height: 18px;
  right: -5px;
  top: -5px;
  background-color: #dc3545;
}
.form-control {
  padding: 12px 16px;
}
</style>
