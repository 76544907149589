import { injectable, inject } from "inversify";

export enum StorageKeys {
  TOKEN = "token",
  CODE_CHALLENGE = "code_challenge",
  CODE_VERIFIER = "code_verifier",
  USER = "user",
  IS_FROZEN = "is_frozen",
  Active_Role = "active_role",
  Current_Invitation = "current_invitation",
}

@injectable()
export class StorageService {
  private storage: Storage;

  constructor(@inject("Storage") storage: Storage) {
    this.storage = storage;
  }

  /**
   * Gets the value
   * @param key
   */
  public async get<T = unknown>(key: StorageKeys): Promise<T | null> {
    const value = this.storage.getItem(key);
    if (value === null) {
      return null;
    }
    return JSON.parse(value);
  }

  /**
   * Sets the value
   * @param key
   * @param value
   */
  public async set<T = unknown>(key: StorageKeys, value: T): Promise<boolean> {
    this.storage.setItem(key, JSON.stringify(value));
    return true;
  }

  /**
   * Removes the value of a key
   * @param key
   */
  public async remove(key: StorageKeys): Promise<boolean> {
    this.storage.removeItem(key);
    return true;
  }
  public async removeAll() {
    return this.storage.clear();
  }
}
