<script setup lang="ts">
import { onMounted, ref } from "vue";
import { appContainer } from "../../../container";
import { CompanyService } from "../../../services/manageCompanyService";
import { useRoute } from "vue-router";

const companyService = appContainer.resolve(CompanyService);
const description = ref("");
const route = useRoute();
onMounted(async () => {
  await getDescription();
});
async function getDescription() {
  const currentCompany = await companyService
    .fetchCompanies()
    .then((result) =>
      result.items.find((item) => item.id === Number(route.params.id)),
    );
  description.value = currentCompany!.description;
}
</script>
<template>
  {{ description }}
</template>
