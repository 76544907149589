import { defineStore } from "pinia";
import { appContainer } from "../container";
import { CompanyService } from "../services/manageCompanyService";
import { ref } from "vue";

export const CompanyQuestionFrozenStatus = defineStore(
  "frozenCompanyQuestion",
  () => {
    const companyService = appContainer.resolve(CompanyService);

    const isFrozen = ref(false);

    async function getQuestionStatus(id: number) {
      await companyService
        .getCompanyQuestionStatus(id)
        .then((result) => {
          if (result.frozen_on && !result.unfrozen_on) {
            isFrozen.value = true;
            return;
          }
          if (result.frozen_on > result.unfrozen_on) {
            isFrozen.value = true;
          } else {
            isFrozen.value = false;
          }
        })
        .catch(() => {
          isFrozen.value = false;
        });
    }
    async function toggleFrozenStatus(id: number, newStatus: boolean) {
      await companyService.freezeCompanyQuestions(id, newStatus);
      await getQuestionStatus(id);
    }

    return { isFrozen, getQuestionStatus, toggleFrozenStatus };
  },
);
