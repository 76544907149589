<script setup lang="ts">
import { onMounted, ref } from "vue";
import { appContainer } from "../container";
import { StorageKeys, StorageService } from "../services/storage.service";

const props = defineProps<{
  category: string;
  imgSrc?: string | null;
}>();

const storageService = appContainer.resolve(StorageService);

const emits = defineEmits(["logoUpdated"]);
const logo = ref<File>();
const logoUpload = ref();
const logoSrc = ref();
const token = ref("");
onMounted(async () => {
  if (props.imgSrc) {
    token.value = (
      (await storageService.get(StorageKeys.TOKEN)) as any
    ).access_token;
    logoSrc.value = `${props.imgSrc}?token=${token.value}`;
  }
});

function handleFileUpload(event: Event) {
  const target = event.target as HTMLInputElement;
  const files = target.files;
  if (files && files[0]) {
    logo.value = files[0];
    logoSrc.value = URL.createObjectURL(logo.value);
  }
  emits("logoUpdated", logo.value, props.category);
}
</script>
<template>
  <div class="col-3 pe-5">
    <div
      class="position-relative d-flex align-items-center justify-content-between h-100"
    >
      <label for="logo">Upload Logo</label>
      <input
        ref="logoUpload"
        type="file"
        class="form-control d-none"
        @change="handleFileUpload"
      />
      <button
        type="button"
        class="btn btn-primary text-white"
        @click="logoUpload.click()"
      >
        Add File
      </button>
    </div>
  </div>
  <div v-if="logoSrc" class="col-3 pe-5">
    <div
      class="position-relative d-flex align-items-center justify-content-between h-100"
    >
      <label for="lead">Preview</label>
      <img :src="logoSrc" alt="" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
img {
  object-fit: contain;
  height: 60px;
  width: 60px;
}
</style>
