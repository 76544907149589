import type { NavigationGuardWithThis } from "vue-router";
import { AuthService } from "../services/authService";
import { appContainer } from "../container";

export const redirectIfAuthenticatedGuard: NavigationGuardWithThis<
  undefined
> = async () => {
  const authService: AuthService = appContainer.resolve(AuthService);

  await authService.initAuthState();

  return authService.user() ? { path: "/dashboard" } : true;
};
