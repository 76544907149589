<template>
  <div class="alert alert-danger error-wrapper" v-if="showErrors">
    <slot></slot>
    <ul class="mb-0">
      <li v-for="(errorValue, index) in allErrors" :key="index">
        {{ errorValue }}
      </li>
    </ul>
  </div>
</template>
<script setup lang="ts">
import { computed } from "vue";
import { ErrorResponse } from "../interface/ErrorInterface";

// Define props
const props = defineProps<{ validations: ErrorResponse }>();

// Computed property to check if there are any errors
const showErrors = computed(() => {
  return Object.keys(props.validations.errors).length > 0;
});

// Computed property to flatten all errors into a single array
const allErrors = computed(() => {
  let errors: string[] = [];
  for (const errorKey in props.validations.errors) {
    errors = errors.concat(props.validations.errors[errorKey]);
  }
  return errors;
});
</script>

<style scoped lang="scss">
.error-wrapper {
  width: 90%;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
}
</style>
