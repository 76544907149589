export function debounce(
  fn: (...args: any) => any,
  timeout: number,
): () => void {
  let timerId: number;
  return () => {
    clearTimeout(timerId);
    timerId = setTimeout(fn, timeout);
  };
}
