<script setup lang="ts">
import { onMounted, ref } from "vue";
import { InvitedUserInterface, Role } from "../../interface/UserInterface";
import { appContainer } from "../../container";
import { UserService } from "../../services/userService";
import UserCardListing from "../manage-users/components/UserListingCard.vue";
import { useRoute } from "vue-router";

const allAdmins = ref<InvitedUserInterface[]>();
const userService = appContainer.resolve(UserService);
const route = useRoute();

onMounted(async () => {
  await getAdminList();
});
async function getAdminList() {
  const isAll = true;
  if (Number(route.params.id)) {
    return userService
      .getInvitedUser({
        invitationType: Role.Admin,
        all: isAll,
        companyId: Number(route.params.id),
      })
      .then((result) => {
        allAdmins.value = result.items.filter(
          (item) => item.invitation_sent === true,
        );
      });
  } else {
    return userService
      .getInvitedUser({
        invitationType: Role.Admin,
        all: isAll,
      })
      .then((result) => {
        allAdmins.value = result.items.filter(
          (item) => item.invitation_sent === true,
        );
      });
  }
}
</script>
<template>
  <h2 class="fw-medium fs-4 mb-3">Manage Reports</h2>
  <h3 class="fw-medium fs-5 mb-3 ff-montserrat">List of Admins</h3>
  <UserCardListing
    v-for="admin of allAdmins"
    :showDetail="false"
    :user="admin"
    :key="admin.id"
    :forReports="true"
  />
</template>
