<script lang="ts" setup>
import { onMounted, ref, watch } from "vue";
import {
  QuestionInputTypes,
  QuestionInterface,
  QuestionResponse,
  ResponseInterFace,
} from "../../interface/QuestionInterface";

const props = defineProps<{
  question: QuestionInterface;
  index: number;
  currentResponse?: QuestionResponse;
  preview?: boolean;
}>();

const ratingScale = [
  {
    value: 0,
    class: "not-observed",
  },
  {
    value: 1,
    class: "not-at-all",
  },
  {
    value: 2,
    class: "slightly",
  },
  {
    value: 3,
    class: "somewhat",
  },
  {
    value: 4,
    class: "moderately",
  },
  {
    value: 5,
    class: "usually",
  },
  {
    value: 6,
    class: "completely",
  },
];

const emits = defineEmits(["updateResponse"]);

const response = ref<ResponseInterFace>({
  id: null,
  rating_response: null,
  paragraph_response: null,
});

onMounted(() => {
  if (!props.preview) {
    initResponses();
  }
});

watch(() => props.currentResponse, setResopnseId);

function setResopnseId() {
  initResponses();
}
const handleResponseChange = () => {
  if (!props.preview) {
    emits("updateResponse", response.value);
  }
};

function initResponses() {
  response.value.id = props.currentResponse!.id;
  response.value.paragraph_response = props.currentResponse!.paragraph_response;
  response.value.rating_response = props.currentResponse!.rating_response;
  handleResponseChange();
}

watch(() => response.value.rating_response, handleResponseChange);
watch(() => response.value.paragraph_response, handleResponseChange);
</script>

<template>
  <template v-if="props.question.input_type === QuestionInputTypes.Rating">
    <div class="question-card py-3 align-items-center">
      <div class="question">
        <span class="me-2">{{ props.index }}. </span>
        {{ props.question.question_text }}
      </div>
      <div
        class="rate-btn"
        v-for="(rating, index) in ratingScale"
        :key="index"
        :class="rating.class"
      >
        <input
          type="radio"
          :name="`question-${props.index}`"
          :value="rating.value"
          v-model="response!.rating_response"
        />
        <button class="btn btn-outline-primary">{{ rating.value }}</button>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="question-card py-3 align-items-center">
      <div class="question">
        <span class="me-2">{{ props.index }}. </span>
        {{ props.question.question_text }}
      </div>

      <div class="text-area mt-3">
        <textarea
          class="form-control"
          v-model="response!.paragraph_response"
          maxlength="600"
        ></textarea>
      </div>
    </div>
  </template>
</template>

<style lang="scss" scoped>
@import "../../theme/variables";

.rate-btn {
  display: flex;
  position: relative;
  justify-content: center;

  &::before {
    display: none;
    position: absolute;
    bottom: 80px;
    font-size: 12px;
    text-align: center;
    width: 100%;
    color: #999;
    font-weight: 600;
    left: 0;
  }

  button {
    --bs-btn-hover-color: #fff;
    --bs-btn-active-color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 0 !important;
  }
  span {
    font-size: 13px;
    text-align: center;
  }
  input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
}

.not-observed {
  button {
    border-color: #999;
    color: #999;
  }
  &::before {
    content: "Unknown";
  }
}
.not-at-all::before {
  content: "Not at All";
}
.slightly::before {
  content: "Slightly";
}

.somewhat::before {
  content: "Somewhat";
}
.moderately::before {
  content: "Moderately";
}

.usually::before {
  content: "Usually";
}

.completely::before {
  content: "Completely";
}

.text-area {
  grid-column: 6/13;

  textarea {
    height: 100px;
  }
}

.question-card {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 10px;
  border-radius: 6px;
  padding-left: 16px;
  padding-right: 16px;
  &:last-child {
    border: none;
  }
  &:first-of-type {
    .rate-btn::before {
      display: block;
    }
  }
  &:nth-of-type(odd) {
    background-color: #b3b1b118;
  }
  .question {
    grid-column: 1/6;
    font-size: 18px;
    display: flex;
  }
}

.rate-btn:has(input:checked) {
  button {
    background-color: $blue;
    border-color: $blue;
    color: #fff !important;
  }
}

@media (max-width: 1639px) {
  .question-card {
    grid-template-columns: repeat(11, 1fr);
    gap: 0;
    .question {
      grid-column: 1/5;
    }
  }
}

@media (max-width: 1440px) {
  .question {
    font-size: 20px !important;
  }
}
@media (max-width: 1199px) {
  .question-card {
    grid-template-columns: repeat(7, 1fr);
    .question {
      grid-column: 1/8;
    }
    .text-area {
      grid-column: 1/13;
    }
  }
  .rate-btn {
    grid-row: 2;
    margin-top: 50px;
    margin-bottom: 20px;
    &::before {
      display: block;
      bottom: 50px;
    }
  }
}
@media (max-width: 767px) {
  .rate-btn {
    margin-top: 50px;
    margin-bottom: 20px;
    &.not-observed {
      margin-right: 0 !important;
      padding-right: 20px;
      padding-left: 10px;
    }
    button {
      width: 30px;
      height: 30px;
      font-size: 14px;
    }
  }
  .rate-btn::before {
    bottom: 40px;
  }
  .rate-btn:not(.not-observed) {
    &::before {
      display: none !important;
    }
  }
}
</style>
