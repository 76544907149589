import { NavigationGuardWithThis } from "vue-router";

import { CurrentInvitationStore } from "../stores/CurrentInvtationStore";
import { appContainer } from "../container";
import { UserService } from "../services/userService";
import { Role } from "../interface/UserInterface";

export const FeedbackExpiredGaurd: NavigationGuardWithThis<
  undefined
> = async () => {
  const userService = appContainer.resolve(UserService);
  const currentInvitatonStore = CurrentInvitationStore();

  if (
    currentInvitatonStore.currentInvitation?.invitation_type ===
    Role.Participants
  ) {
    await currentInvitatonStore.getCurrentInvitation();

    return new Date() >
      new Date(
        currentInvitatonStore.currentInvitation.meta.feedback_completion_date,
      )
      ? "/feedback-expired"
      : true;
  } else if (
    currentInvitatonStore.currentInvitation?.invitation_type === Role.Raters
  ) {
    await currentInvitatonStore.getCurrentInvitation();
    const participant = await userService
      .getInvitedUser({
        invited_user:
          currentInvitatonStore.currentInvitation?.invited_for_user?.id,
        invitationType: Role.Participants,
        companyId: currentInvitatonStore.currentInvitation.company_id,
      })
      .then((result) => result.items[0]);

    const participantData = await userService.feedbackDurationlleft(
      participant.id,
    );
    return participantData.days_left === 0 ? "/feedback-expired" : true;
  } else {
    return true;
  }
};
