<script setup lang="ts">
import { ref } from "vue";
import { AxiosError } from "axios";
import { appContainer } from "../../../container";
import { ErrorResponse } from "../../../interface/ErrorInterface";
import { LoadingService } from "../../../services/loadingService";
import { CompanyService } from "../../../services/manageCompanyService";
import { ToastService } from "../../../services/toastService";
import ValidationErrorComponent from "../../../components/ValidationErrorComponent.vue";

const props = defineProps<{
  companyId: number;
}>();

const questionData = ref({
  question_text: "",
});

const errors = ref({
  question_text: "",
});

const emit = defineEmits(["close", "add"]);

const validationErrors = ref<ErrorResponse>();
const loadingService = appContainer.resolve(LoadingService);
const toastService = appContainer.resolve(ToastService);
const companyService = appContainer.resolve(CompanyService);

function validateForm() {
  let isValid = true;

  // Reset errors
  errors.value = {
    question_text: "",
  };

  // Validate question text
  if (!questionData.value.question_text) {
    errors.value.question_text = "Question is required.";
    isValid = false;
  }

  return isValid;
}

async function addQuestion() {
  if (validateForm()) {
    const loader = await loadingService.show();
    companyService
      .addCompanyQuestion(props.companyId, questionData.value)
      .then(() => {
        toastService.success("Question Added Successfully");
        emit("add");
      })
      .catch((err) => {
        const error = err as AxiosError;
        const errorResponse = error.response?.data as ErrorResponse;
        validationErrors.value = errorResponse;
      })
      .finally(() => loader.hide());
  }
}
</script>

<template>
  <div class="modal-backdrop">
    <div class="modal fade show" id="add-modal">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <p class="modal-title fw-bold">Add Question</p>
            <button
              type="button"
              class="btn-close"
              @click="emit('close')"
            ></button>
          </div>
          <ValidationErrorComponent
            v-if="validationErrors"
            :validations="validationErrors"
          ></ValidationErrorComponent>
          <form @submit.prevent="addQuestion" class="w-100 overflow-y-auto">
            <div class="modal-body">
              <div class="mb-3">
                <label class="form-label fw-bold">Question</label>
                <textarea
                  v-model="questionData.question_text"
                  class="form-control"
                  rows="5"
                  data-cy=""
                  maxlength="250"
                ></textarea>
                <div v-if="errors.question_text" class="error mt-2 text-danger">
                  {{ errors.question_text }}
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="submit"
                class="btn btn-primary btn-sm mx-auto text-white"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.error {
  font-size: 14px;
}
</style>
