<script setup lang="ts">
import { onMounted, ref } from "vue";
import { appContainer } from "../container";
import { ManageQuestionService } from "../services/manageQuestionService";
import { QuestionInterface } from "../interface/QuestionInterface";
import RaterQuestionComponent from "../views/rater/RaterQuestionComponent.vue";
import { PagingInterface } from "../interface/PaginationInterface";
import PaginationComponent from "./PaginationComponent.vue";

const questionService = appContainer.resolve(ManageQuestionService);

const questions = ref<QuestionInterface[]>();
const paginatedQuestions = ref<PagingInterface<QuestionInterface>>();
const isLoading = ref(true);

onMounted(() => {
  fetchQuestions({ page: 1, offset: 8 });
});

async function fetchQuestions(pageConfig: { page: number; offset: number }) {
  questionService.getQuestion(pageConfig).then((result) => {
    paginatedQuestions.value = result;
    questions.value = result.items;
    isLoading.value = false;
  });
}

function updatePage(pageNumber: number) {
  if (pageNumber === paginatedQuestions.value?.page) {
    return;
  }
  fetchQuestions({ page: pageNumber, offset: 8 });
}
</script>

<template>
  <template v-if="!isLoading">
    <div
      class="question-header py-2 pb-xl-5"
      v-if="paginatedQuestions?.page != paginatedQuestions?.totalPages"
    >
      <strong class="fs-5">Questions</strong>
      <p class="mt-2">Please click on the number you find most appropriate.</p>
    </div>
    <div v-else class="question-header py-2 pb-xl-5">
      <strong class="fs-5">Written Comments</strong>
    </div>

    <template v-if="paginatedQuestions?.items">
      <div class="all-questions d-flex flex-column">
        <RaterQuestionComponent
          v-for="(question, index) in questions"
          :key="index"
          :question="question"
          :index="
            paginatedQuestions
              ? (paginatedQuestions.page - 1) * paginatedQuestions.offset +
                (index + 1)
              : 1
          "
          :questionResponse="null"
          :preview="true"
        />
      </div>
      <PaginationComponent
        :totalRows="paginatedQuestions?.totalItems"
        :currentPage="paginatedQuestions?.page"
        :perPage="paginatedQuestions?.offset"
        @pageChange="updatePage"
      />
    </template>
  </template>
</template>

<style scoped lang="scss">
.all-questions {
  min-height: calc(100vh - 290px);
}
</style>
