<script setup lang="ts">
import { ref } from "vue";
import { ErrorResponse } from "../../interface/ErrorInterface";
import { appContainer } from "../../container";
import { ManageQuestionService } from "../../services/manageQuestionService";
import { LoadingService } from "../../services/loadingService";
import { ToastService } from "../../services/toastService";
import { AxiosError } from "axios";
import ValidationErrorComponent from "../../components/ValidationErrorComponent.vue";

const emit = defineEmits(["close", "upload"]);
const csv = ref<File | null>(null);
const errors = ref<string | null>(null);

const validationErrors = ref<ErrorResponse>();
const manageQuestionService = appContainer.resolve(ManageQuestionService);
const loadingService = appContainer.resolve(LoadingService);
const toastService = appContainer.resolve(ToastService);

const handleFileUpload = (e: Event) => {
  const target = e.target as HTMLInputElement;
  if (target.files && target.files.length > 0) {
    csv.value = target.files[0];
    // Validate file extension
    if (!csv.value.name.endsWith(".csv")) {
      errors.value = "Only CSV files are allowed.";
    } else {
      errors.value = null;
    }
  } else {
    csv.value = null;
  }
};

async function uploadQuestions() {
  if (!csv.value) {
    errors.value = "Please select a CSV file.";
    return;
  }
  if (!csv.value.name.endsWith(".csv")) {
    errors.value = "Only CSV files are allowed.";
    return;
  }

  const loader = await loadingService.show();
  manageQuestionService
    .uploadCsv(csv.value)
    .then(() => {
      emit("upload");
      toastService.success("CSV Uploaded Successfully");
    })
    .catch((err) => {
      const error = err as AxiosError;
      const errorResponse = error.response?.data as ErrorResponse;
      validationErrors.value = errorResponse;
    })
    .finally(() => loader.hide());
}
</script>

<template>
  <div class="modal-backdrop">
    <div class="modal fade show" id="edit-modal">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <p class="modal-title fw-bold">Upload CSV</p>
            <button
              type="button"
              class="btn-close"
              @click="emit('close')"
            ></button>
          </div>
          <ValidationErrorComponent
            v-if="validationErrors"
            :validations="validationErrors"
          ></ValidationErrorComponent>
          <form class="w-100 overflow-y-auto" @submit.prevent="uploadQuestions">
            <div class="modal-body">
              <div class="mb-3">
                <div class="d-flex w-100 align-items-start">
                  <input
                    type="file"
                    class="form-control"
                    accept=".csv"
                    @change="handleFileUpload"
                  />
                </div>
                <div v-if="errors" class="error mt-2 text-danger">
                  {{ errors }}
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="submit"
                class="btn btn-primary btn-sm mx-auto text-white"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.error {
  font-size: 14px;
}
</style>
