import type { NavigationGuardWithThis } from "vue-router";
import { AuthService } from "../services/authService";
import { appContainer } from "../container";
import { ActiveRoleStore } from "../stores/ActiveRoleStore";
import { StorageKeys, StorageService } from "../services/storage.service";
import {
  InvitedUserInterface,
  RoleInterface,
} from "../interface/UserInterface";
import { ref } from "vue";
import { UserService } from "../services/userService";

export const HasMultipleRoles: NavigationGuardWithThis<
  undefined
> = async () => {
  const authService: AuthService = appContainer.resolve(AuthService);
  const useRoleStore = ActiveRoleStore();

  await useRoleStore.getActiveRole();

  const user = authService.user();

  const storageService = appContainer.resolve(StorageService);
  const userService = appContainer.resolve(UserService);

  const activeRole = ref<RoleInterface>();

  const currentInvitation = ref<InvitedUserInterface>();
  await storageService.get(StorageKeys.Active_Role).then((result) => {
    activeRole.value = result as RoleInterface;
  });
  await storageService.get(StorageKeys.Current_Invitation).then((result) => {
    currentInvitation.value = result as InvitedUserInterface;
  });
  const invitations = await userService
    .getInvitedUser({
      invited_user: user?.id,
      all: true,
      allUsers: true,
    })
    .then((result) => {
      return result.items;
    });
  if (user?.roles[0].role_name === "super_admin") {
    return true;
  }
  if (invitations.length === 1) {
    await storageService.set(StorageKeys.Current_Invitation, invitations[0]);
    return true;
  } else if (!currentInvitation.value) {
    return { path: "/choose-account" };
  }
};
