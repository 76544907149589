import type { RequestInterceptor } from "../interface/interceptorInterface";
import { type AxiosRequestConfig, type AxiosRequestHeaders } from "axios";
import { ApiCallService } from "../services/apiCall.service";
import { appContainer } from "../container";
import { StorageKeys, StorageService } from "../services/storage.service";
import { TokenInterface } from "../interface/TokenInterface";

export const AuthorizationTokenInterceptor: RequestInterceptor = [
  async (requestConfig: AxiosRequestConfig) => {
    const apiCallService = appContainer.resolve(
      ApiCallService,
    ) as ApiCallService;
    const storageService = appContainer.resolve(
      StorageService,
    ) as StorageService;

    const token: TokenInterface | null =
      await storageService.get<TokenInterface>(StorageKeys.TOKEN);
    if (requestConfig.url?.includes(apiCallService.apiVersion())) {
      (requestConfig.headers as AxiosRequestHeaders).Authorization =
        `Bearer ${token?.access_token}`;
    }
    return requestConfig as AxiosRequestConfig;
  },
];
// if error is not unauthorized then let it return as it is
// if error is the unauthorized exception then keep a count of refresh token success attempts
// fetch new token using refresh token (if refresh token attempt fails then exit the system)
// using the new tokens retry the current failed request mapped to response and update count of attempts
// if still getting unauthorized and attempts are not exhausted then retry the same flow
// if attempts get exhausted then exit the system
// const originalRequest = response.config;
