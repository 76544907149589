import "reflect-metadata";
import { createApp } from "vue";
import "./theme/bootstrap.scss";
import "./style.scss";
import "bootstrap";
import { createPinia } from "pinia";

import router from "./routes";

import "bootstrap-icons/font/bootstrap-icons.css";

import App from "./App.vue";
import { LoadingPlugin } from "vue-loading-overlay";
import { InterceptorHandlers } from "./services/axiosInterceptor";
import { appContainer } from "./container";
import ToastPlugin from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";

const interceptorHandler: InterceptorHandlers = appContainer.resolve(
  InterceptorHandlers,
) as InterceptorHandlers;
interceptorHandler.init();
const pinia = createPinia();

const app = createApp(App)
  .use(router)
  .use(LoadingPlugin)
  .use(ToastPlugin)
  .use(pinia);

app.mount("#app");
