<script setup lang="ts">
import { appContainer } from "../../container";
import { ErrorResponse } from "../../interface/ErrorInterface";
import { LoadingService } from "../../services/loadingService";
import { ToastService } from "../../services/toastService";
import { ref } from "vue";
import ValidationErrorComponent from "../../components/ValidationErrorComponent.vue";
import { ReportService } from "../../services/reportService";

const props = defineProps<{
  invitationId: number;
  responseId: number | null;
}>();
const emit = defineEmits(["close", "delete"]);

const validationErrors = ref<ErrorResponse>();
const loadingService = appContainer.resolve(LoadingService);
const toastService = appContainer.resolve(ToastService);
const reportService = appContainer.resolve(ReportService);

async function removeResponse() {
  console.log(props.invitationId);
  const loader = await loadingService.show();
  reportService
    .UpdateParagraphResponse(props.invitationId, props.responseId as number)
    .then(() => {
      toastService.success("Response Removed Successfully");
      emit("delete");
    })
    .catch(() => toastService.error("Unable to Delete Response"))
    .finally(() => loader.hide());
}
</script>

<template>
  <div class="modal-backdrop">
    <div class="modal" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <ValidationErrorComponent
              v-if="validationErrors"
              :validations="validationErrors"
            ></ValidationErrorComponent>
            <h5 class="text-center my-4 ff-montserrat">
              Are you sure you want to delete this comment?
            </h5>
            <div class="d-flex align-items-center justify-content-evenly p-4">
              <button
                type="button"
                class="btn btn-outline-primary"
                @click="emit('close')"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-danger text-white"
                @click="removeResponse"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
button {
  &:hover {
    color: #fff;
  }
}
</style>
