import { inject, injectable } from "inversify";
import { ApiCallService } from "./apiCall.service";
import axios from "axios";
import {
  ThemeInterface,
  LinksInterface,
  LinkFor,
} from "../interface/ThemeInterface";

@injectable()
export class ThemeService {
  constructor(@inject(ApiCallService) public apiCallService: ApiCallService) {}

  // save ThemeColors
  public async saveTheme(data: ThemeInterface): Promise<ThemeInterface> {
    return axios
      .put<ThemeInterface>(this.apiCallService.apiUrl("themes"), {
        ...data,
      })
      .then((result) => result.data);
  }

  // Save Links
  public async saveLinks(link: string, type: LinkFor): Promise<LinksInterface> {
    return axios
      .patch<LinksInterface>(this.apiCallService.apiUrl(`links/${type}`), {
        link,
      })
      .then((result) => result.data);
  }

  public async categoryLogo(
    category: string,
    logo: File,
  ): Promise<ThemeInterface> {
    const formData = new FormData();
    formData.append("file", logo);
    return axios
      .post<ThemeInterface>(
        this.apiCallService.apiUrl(`themes/category/${category}/logo`),
        formData,
      )
      .then((result) => result.data);
  }

  public async brandLogo(logo: File): Promise<ThemeInterface> {
    const formData = new FormData();
    formData.append("file", logo);
    return axios
      .post<ThemeInterface>(this.apiCallService.apiUrl(`themes/logo`), formData)
      .then((result) => result.data);
  }

  public async getThemeData(): Promise<ThemeInterface> {
    return axios
      .get<ThemeInterface>(this.apiCallService.apiUrl("themes"))
      .then((result) => result.data);
  }
}
