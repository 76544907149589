import { defineStore } from "pinia";
import { appContainer } from "../container";
import { StorageKeys, StorageService } from "../services/storage.service";
import { ref } from "vue";
import { RoleInterface } from "../interface/UserInterface";

export const ActiveRoleStore = defineStore("activeRole", () => {
  const storageService = appContainer.resolve(StorageService);

  const activeRole = ref<RoleInterface | null>(null);

  async function getActiveRole() {
    activeRole.value = (await storageService.get(
      StorageKeys.Active_Role,
    )) as RoleInterface;
  }
  return {
    getActiveRole,
    activeRole,
  };
});
