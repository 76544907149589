<script setup lang="ts">
import { appContainer } from "../../container";
import { LoadingService } from "../../services/loadingService";
import { StorageKeys, StorageService } from "../../services/storage.service";
import { UserService } from "../../services/userService";
import { CurrentInvitationStore } from "../../stores/CurrentInvtationStore";

const emits = defineEmits(["close", "submitted"]);

const userService = appContainer.resolve(UserService);
const loadingService = appContainer.resolve(LoadingService);
const currentInvitationStore = CurrentInvitationStore();
const storageService = appContainer.resolve(StorageService);

async function handleFinishFeedback() {
  const loader = await loadingService.show();
  await userService
    .finsihFeedback(currentInvitationStore.currentInvitation!.id)
    .then((result) => {
      storageService.set(StorageKeys.Current_Invitation, result);
      emits("submitted");
    })
    .finally(() => loader.hide());
}
</script>
<template>
  <div class="modal-backdrop">
    <div class="modal" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <h5 class="text-center my-4 ff-montserrat">
              Are you sure you want to finish the assessment?
            </h5>
            <div class="d-flex align-items-center justify-content-evenly p-4">
              <button
                type="button"
                class="btn btn-outline-danger"
                @click="emits('close')"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-secondary text-white"
                @click="handleFinishFeedback"
              >
                Finish
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
button {
  &:hover {
    color: #fff;
  }
}
</style>
