import { inject, injectable } from "inversify";
import { ApiCallService } from "./apiCall.service";
import { ResponseInterFace } from "../interface/QuestionInterface";
import axios from "axios";
import { InvitedUserInterface } from "../interface/UserInterface";
import { StorageKeys, StorageService } from "./storage.service";
import { TokenInterface } from "../interface/TokenInterface";

@injectable()
export class ReportService {
  constructor(
    @inject(ApiCallService) public apiCallService: ApiCallService,
    @inject(StorageService) public storageService: StorageService,
  ) {}

  public async UpdateParagraphResponse(
    invitationId: number,
    responseId: number,
  ): Promise<ResponseInterFace> {
    return axios
      .put<ResponseInterFace>(
        this.apiCallService.apiUrl(
          `users/invitations/${invitationId}/questions/responses/${responseId}`,
        ),
      )
      .then((result) => result.data);
  }

  public async getResponses(
    id: number,
  ): Promise<{ question_responses: ResponseInterFace[] }> {
    return axios
      .get<{
        question_responses: ResponseInterFace[];
      }>(
        this.apiCallService.apiUrl(
          `users/invitations/${id}/questions/responses/comments`,
        ),
      )
      .then((result) => result.data);
  }
  public async saveResponses(id: number): Promise<InvitedUserInterface> {
    return axios
      .post<InvitedUserInterface>(
        this.apiCallService.apiUrl(`users/invitations/${id}/comments-deleted`),
      )
      .then((result) => result.data);
  }
  public async sendReportPdf(
    invitationId: number,
    loginInvitationId: number | null,
  ): Promise<{ status: string }> {
    const token = await this.storageService
      .get<TokenInterface>(StorageKeys.TOKEN)
      .then((result) => result?.access_token);
    return await axios
      .post<{
        status: string;
      }>(
        this.apiCallService.apiUrl(
          `users/invitations/${invitationId}/report-mail/?token=${token}`,
        ),
        loginInvitationId,
      )
      .then((result) => result.data);
  }
}
