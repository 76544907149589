<script setup lang="ts">
import { onMounted, ref } from "vue";
import ModalAddCompany from "./components/ModalAddCompany.vue";
import ListingCompany from "./components/ListingCompany.vue";
import { CompanyInterface } from "../../interface/CompanyInterface";
import { appContainer } from "../../container";
import { CompanyService } from "../../services/manageCompanyService";
import { LoadingService } from "../../services/loadingService";

// Define Modal Show/Hide
const showModal = ref(false);
const closeModal = () => {
  showModal.value = false;
};
const openModal = () => {
  showModal.value = true;
};

const companyService = appContainer.resolve(CompanyService);
const loadingService = appContainer.resolve(LoadingService);

// Define a ref to store received company data as an array
const receivedCompanyData = ref<CompanyInterface[]>([]);

onMounted(() => {
  getCompanies();
});

// Handle the companyAdded event
const handleCompanyAdded = async () => {
  getCompanies();
  closeModal();
};

async function getCompanies() {
  const loader = await loadingService.show();
  companyService
    .fetchCompanies()
    .then((result) => {
      receivedCompanyData.value = result.items;
    })
    .finally(() => loader.hide());
}
</script>

<template>
  <ModalAddCompany
    v-if="showModal"
    @close="closeModal"
    @companyAdded="handleCompanyAdded"
  />
  <div class="wrap-company-view">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h2 class="fw-medium fs-4 p-0">Manage Companies</h2>
      <button class="btn btn-primary btn-sm text-white" @click="openModal">
        Add Company
      </button>
    </div>
    <ListingCompany
      @edit="getCompanies()"
      @copy="getCompanies()"
      @companyDeleted="getCompanies()"
      :receivedCompanyData="receivedCompanyData"
    />
  </div>
</template>

<style scoped lang="scss"></style>
