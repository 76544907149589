import { inject, injectable } from "inversify";
import { ApiCallService } from "./apiCall.service";
import { StorageKeys, StorageService } from "./storage.service";
import axios, { Axios } from "axios";
import type { AxiosInstance } from "axios";
import { TokenInterface } from "../interface/TokenInterface";
import { UserInterface } from "../interface/UserInterface";
import { ref } from "vue";
import router from "../routes";
import { useIsFrozenStore } from "../stores/IsQuestionFrozenStore";

@injectable()
export class AuthService {
  /**
   * The current logged-in user or null
   * @protected
   */

  public userState = ref<UserInterface | null>();

  /**
   * True when all auth service initialization is completed
   * @protected
   */

  protected initializationCompleted = false;
  constructor(
    @inject(ApiCallService) public apiCallService: ApiCallService,
    @inject(StorageService) public storage: StorageService,
    @inject(Axios) public axios: AxiosInstance,
  ) {}

  protected tokenState = ref<TokenInterface | null>();

  public token() {
    return this.tokenState.value;
  }

  public user() {
    return this.userState.value;
  }

  public async login(code: string): Promise<UserInterface> {
    // @if no verifier found then need to handle this as an error
    return axios
      .post<TokenInterface>(this.apiCallService.url("oauth/token"), {
        code_verifier: await this.storage.get(StorageKeys.CODE_VERIFIER),
        code,
        grant_type: "pkce",
      })
      .then((response) => {
        this.storage.set(StorageKeys.TOKEN, response.data);
        this.storage.remove(StorageKeys.CODE_CHALLENGE);
        this.storage.remove(StorageKeys.CODE_VERIFIER);
        return this.fetchUser();
      });
  }

  public async fetchUser(): Promise<UserInterface> {
    return axios
      .get<UserInterface>(this.apiCallService.apiUrl("users"))
      .then((result) => {
        return result.data;
      })
      .then(async (result) => {
        await this.storage.set(StorageKeys.USER, result);
        this.userState.value = result;
        return result;
      });
  }

  /**
   * Initializes auth state
   */
  public async initAuthState(): Promise<boolean> {
    // if (this.initializationCompleted) {
    //   return true;
    // }
    const questionStore = useIsFrozenStore();

    const token = await this.storage.get<TokenInterface>(StorageKeys.TOKEN);
    if (!token) {
      this.tokenState.value = null;
      this.userState.value = null;
      this.initializationCompleted = true;
      return this.initializationCompleted;
    }
    const userState = await this.storage.get<UserInterface>(StorageKeys.USER);
    if (!userState) {
      this.tokenState.value = null;
      this.userState.value = null;
      this.initializationCompleted = true;
      return this.initializationCompleted;
    }

    this.userState.value = userState;
    if (this.userState.value!.roles.length === 1) {
      const activeRole = this.user()?.roles[0];
      await this.storage.set(StorageKeys.Active_Role, activeRole);
      if (this.user()?.roles[0].role_name === "super_admin") {
        await questionStore.getQuestionStatus();
      }
    }
    this.initializationCompleted = true;

    return this.initializationCompleted;
  }

  public async logout(): Promise<{ status: "ok" }> {
    return axios
      .post<{ status: "ok" }>(
        this.apiCallService.url("oauth/logout"),
        {},
        {
          headers: {
            Authorization: `Bearer ${this.tokenState.value?.access_token}`,
          },
        },
      )
      .then((result) => result.data)
      .finally(() => {
        this.userState.value = null;
        this.tokenState.value = null;
        this.storage.removeAll();
        router.push("/login");
      });
  }
}
