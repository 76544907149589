<script setup lang="ts">
import { ref } from "vue";
import {
  PostQuestionInterFace,
  QuestionCategories,
  QuestionInputTypes,
  QuestionSubCategories,
} from "../../interface/QuestionInterface";
import { ManageQuestionService } from "../../services/manageQuestionService";
import { appContainer } from "../../container";
import { LoadingService } from "../../services/loadingService";
import { ToastService } from "../../services/toastService";
import { AxiosError } from "axios";
import { ErrorResponse } from "../../interface/ErrorInterface";
import ValidationErrorComponent from "../../components/ValidationErrorComponent.vue";

const validationErrors = ref<ErrorResponse>();

const questionData = ref<PostQuestionInterFace>({
  question_text: "",
  question_category: null,
  question_subcategory: null,
  input_type: null,
});

const errors = ref({
  question_text: "",
  question_category: "",
  question_subcategory: "",
  input_type: "",
});

const manageQuestionService = appContainer.resolve(ManageQuestionService);
const loadingService = appContainer.resolve(LoadingService);
const toastService = appContainer.resolve(ToastService);

const emit = defineEmits(["close", "add"]);

function validateForm() {
  let isValid = true;

  // Reset errors
  errors.value = {
    question_text: "",
    question_category: "",
    question_subcategory: "",
    input_type: "",
  };

  // Validate question text
  if (!questionData.value.question_text) {
    errors.value.question_text = "Question text is required.";
    isValid = false;
  }

  // Validate question category
  if (
    !questionData.value.question_category &&
    questionData.value.input_type === QuestionInputTypes.Rating
  ) {
    errors.value.question_category = "Please select a category.";
    isValid = false;
  }

  // Validate question subcategory
  if (
    !questionData.value.question_subcategory &&
    questionData.value.input_type === QuestionInputTypes.Rating
  ) {
    errors.value.question_subcategory = "Please select a subcategory.";
    isValid = false;
  }

  // Validate input type
  if (!questionData.value.input_type) {
    errors.value.input_type = "Please select a question type.";
    isValid = false;
  }

  return isValid;
}

async function addQuestion() {
  if (validateForm()) {
    const loader = await loadingService.show();
    manageQuestionService
      .addQuestion(questionData.value)
      .then(() => {
        toastService.success("Question Added Successfully");
        emit("add");
      })
      .catch((err) => {
        const error = err as AxiosError;
        const errorResponse = error.response?.data as ErrorResponse;
        validationErrors.value = errorResponse;
      })
      .finally(() => {
        loader.hide();
      });
  }
}
</script>

<template>
  <div class="modal-backdrop">
    <div class="modal fade show" id="add-modal">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <p class="modal-title fw-bold">Add Question</p>
            <button
              type="button"
              class="btn-close"
              @click="emit('close')"
            ></button>
          </div>
          <ValidationErrorComponent
            v-if="validationErrors"
            :validations="validationErrors"
          ></ValidationErrorComponent>
          <form @submit.prevent="addQuestion" class="w-100 overflow-y-auto">
            <div class="modal-body">
              <div class="mb-3">
                <label class="form-label fw-bold">Question</label>
                <textarea
                  v-model="questionData.question_text"
                  class="form-control"
                  rows="5"
                  data-cy=""
                  maxlength="250"
                ></textarea>
                <div v-if="errors.question_text" class="error mt-2 text-danger">
                  {{ errors.question_text }}
                </div>
              </div>
              <div class="mb-3">
                <label class="form-label fw-bold">Type</label>
                <select
                  v-model="questionData.input_type"
                  class="form-select text-capitalize"
                  data-cy=""
                >
                  <option :value="null" selected>Please Select</option>
                  <option
                    v-for="questionType in QuestionInputTypes"
                    :key="questionType"
                    :value="questionType"
                  >
                    {{ questionType }}
                  </option>
                </select>
                <div v-if="errors.input_type" class="error mt-2 text-danger">
                  {{ errors.input_type }}
                </div>
              </div>
              <div
                class="mb-3"
                v-if="questionData.input_type === QuestionInputTypes.Rating"
              >
                <label class="form-label fw-bold">Category</label>
                <select
                  v-model="questionData.question_category"
                  class="form-select"
                  data-cy=""
                >
                  <option :value="null" selected>Please Select</option>
                  <template
                    v-for="category in QuestionCategories"
                    :key="category"
                  >
                    <option :value="category">
                      {{ category }}
                    </option>
                  </template>
                </select>
                <div
                  v-if="errors.question_category"
                  class="error mt-2 text-danger"
                >
                  {{ errors.question_category }}
                </div>
              </div>

              <div
                class="mb-3"
                v-if="questionData.input_type === QuestionInputTypes.Rating"
              >
                <label class="form-label fw-bold">Sub Category</label>
                <select
                  v-model="questionData.question_subcategory"
                  class="form-select"
                  data-cy=""
                >
                  <option :value="null" selected>Please Select</option>
                  <option
                    v-for="subCategory in QuestionSubCategories"
                    :key="subCategory"
                    :value="subCategory"
                  >
                    {{ subCategory }}
                  </option>
                </select>
                <div
                  v-if="errors.question_subcategory"
                  class="error mt-2 text-danger"
                >
                  {{ errors.question_subcategory }}
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="submit"
                class="btn btn-primary btn-sm mx-auto text-white"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.error {
  font-size: 14px;
}
</style>
