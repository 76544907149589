import { injectable, inject } from "inversify";
import axios from "axios";
import {
  PostQuestionInterFace,
  QuestionInfo,
  QuestionInterface,
  QuestionResponse,
  QuestionResponseFilter,
  QuestionTypes,
  ResponseInterFace,
} from "../interface/QuestionInterface";
import { ApiCallService } from "./apiCall.service";
import { PagingInterface } from "../interface/PaginationInterface";

@injectable()
export class ManageQuestionService {
  constructor(@inject(ApiCallService) public apiCallService: ApiCallService) {}

  public async addQuestion(
    data: PostQuestionInterFace,
  ): Promise<QuestionInterface> {
    return axios
      .post<QuestionInterface>(this.apiCallService.apiUrl("questions"), data)
      .then((result) => result.data);
  }

  public async updateQuestion(
    updatedQuestion: QuestionInterface,
  ): Promise<QuestionInterface> {
    return axios
      .put<QuestionInterface>(
        this.apiCallService.apiUrl(`questions/${updatedQuestion.id}`),
        updatedQuestion,
      )
      .then((result) => result.data);
  }

  public async getQuestion(
    pageConfig: { page?: number; offset?: number } = {
      page: 1,
      offset: 8,
    },
    questionType: string | null = QuestionTypes.Standard,
    companyId: number | null = null,
    all: boolean = false,
  ): Promise<PagingInterface<QuestionInterface>> {
    return axios
      .get<PagingInterface<QuestionInterface>>(
        this.apiCallService.apiUrl("questions"),
        {
          params: {
            ...pageConfig,
            companyId,
            questionType,
            all,
          },
        },
      )
      .then((result) => result.data);
  }
  public async deleteQuestion(id: number) {
    return axios.delete<{ status: string }>(
      this.apiCallService.apiUrl(`questions/${id}`),
    );
  }

  public async uploadCsv(csv: File): Promise<{ status: string }> {
    const formData = new FormData();
    formData.append("file", csv);
    return axios
      .post<{
        status: string;
      }>(this.apiCallService.apiUrl("questions/csv"), formData)
      .then((result) => result.data);
  }
  public async freezeQuestions(freeze: boolean): Promise<QuestionInfo> {
    return axios
      .put<QuestionInfo>(this.apiCallService.apiUrl("questions/freeze"), {
        freeze,
      })

      .then((result) => result.data);
  }
  public async getQuestionStatus(): Promise<QuestionInfo> {
    return axios
      .get<QuestionInfo>(this.apiCallService.apiUrl("questions/frozen-status"))
      .then((result) => result.data);
  }

  public async getFeedbackResponse(
    questionFilter: Partial<QuestionResponseFilter>,
  ): Promise<PagingInterface<QuestionResponse>> {
    return axios
      .get<PagingInterface<QuestionResponse>>(
        this.apiCallService.apiUrl(
          `users/invitations/${questionFilter.invitationId}/questions/responses`,
        ),
        {
          params: { ...questionFilter },
        },
      )
      .then((result) => result.data);
  }
  public async submitResponse(
    responseArray: ResponseInterFace[],
    id: number,
  ): Promise<QuestionResponse[]> {
    return axios
      .put<
        QuestionResponse[]
      >(this.apiCallService.apiUrl(`users/invitations/${id}/questions/responses`), responseArray)
      .then((result) => result.data);
  }
  public async getCurrentPage(id: number): Promise<QuestionResponse> {
    return axios
      .get<QuestionResponse>(
        this.apiCallService.apiUrl(
          `users/invitations/${id}/questions/responses/first-empty-response`,
        ),
      )
      .then((result) => result.data);
  }
}
