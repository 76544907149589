<script setup lang="ts">
import router from "../../routes";
import { CurrentInvitationStore } from "../../stores/CurrentInvtationStore";

const currentInvitationStore = CurrentInvitationStore();
</script>
<template>
  <div class="page-wrapper h-100 position-relative">
    <div
      class="d-flex justify-content-between align-items-center flex-wrap mb-4"
    >
      <h2 class="fw-medium fs-4">Instructions</h2>
      <button
        class="btn btn-primary text-white ms-auto d-block px-4 start-btn"
        @click="router.push('/instructions/sample-question')"
      >
        Next
      </button>
    </div>
    <div class="instructions">
      <p>
        Thank you for your participation in the Trusted Leader 360° Assessment,
        which
        <strong>
          {{
            currentInvitationStore.currentInvitation?.invited_for_user
              ?.first_name
          }}
          {{
            currentInvitationStore.currentInvitation?.invited_for_user
              ?.last_name
          }}
        </strong>
        has requested as part of their leadership development. This tool is
        designed to provide objective feedback through numerical scoring on key
        aspects of leadership. Your thoughtful and honest responses will play a
        crucial role in
        <strong>
          {{
            currentInvitationStore.currentInvitation?.invited_for_user
              ?.first_name
          }}
          {{
            currentInvitationStore.currentInvitation?.invited_for_user
              ?.last_name
          }}&#39;s
        </strong>
        development.
      </p>

      <p>
        Your scores will remain anonymous and will be combined with those of
        other raters, with the exception of the Supervisor, who is typically the
        only individual identified by that designation. Towards the end of the
        assessment, you will also have the opportunity to provide additional
        comments to support
        <strong>
          {{
            currentInvitationStore.currentInvitation?.invited_for_user
              ?.first_name
          }}
          {{
            currentInvitationStore.currentInvitation?.invited_for_user
              ?.last_name
          }}&#39;s
        </strong>
        growth and offer general feedback.
      </p>
      <p class="mt-5">
        Click on the <strong>Next Button</strong> above to read the Sample
        Questions
      </p>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.brand-logo {
  height: 50px;
  img {
    height: 100%;
    width: 100%;
  }
}
.instructions {
  max-width: 1400px;
}

.page-footer {
  bottom: 50px;
}
@media (max-width: 575px) {
  .start-btn {
    padding: 6px;
    font-size: 14px;
  }
}
</style>
