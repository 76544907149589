<script setup lang="ts">
import { onMounted, ref } from "vue";
import { InvitedUserInterface, Role } from "../interface/UserInterface";

const props = defineProps<{
  user: InvitedUserInterface;
}>();

const iconName = ref("");

onMounted(() => {
  switch (props.user.invitation_type) {
    case Role.Admin:
      iconName.value = "bi-gear-fill";
      break;
    case Role.Participants:
      iconName.value = "bi-person-fill";
      break;
    case Role.Raters:
      iconName.value = "bi-star-fill";
      break;
    default:
      iconName.value = "bi-box";
  }
});
</script>
<template>
  <div class="icon-wrapper d-flex align-items-center justify-content-center">
    <i class="bi" :class="`${iconName}`"></i>
  </div>
</template>
<style lang="scss" scoped>
@import "../theme/variables";

.icon-wrapper {
  background-color: #eaf1fb;
  color: $blue;
  padding: 10px;
  width: 64px;
  height: 64px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 24px;
}
</style>
