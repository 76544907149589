<script setup lang="ts">
import { onBeforeMount, onMounted, provide, ref } from "vue";
import { appContainer } from "../../../container";
import { CompanyService } from "../../../services/manageCompanyService";
import { CompanyInterface } from "../../../interface/CompanyInterface";
import { useRoute } from "vue-router";
import { StorageKeys, StorageService } from "../../../services/storage.service";
import router from "../../../routes";
import { CompanyQuestionFrozenStatus } from "../../../stores/CompanyQuestionStore";
import { ToastService } from "../../../services/toastService";

const companyService = appContainer.resolve(CompanyService);
const route = useRoute();

const questionStatus = CompanyQuestionFrozenStatus();
const toastService = appContainer.resolve(ToastService);

onBeforeMount(async () => {
  await getCurrentCompany();
  token.value = (
    (await storageService.get(StorageKeys.TOKEN)) as any
  ).access_token;
});

onMounted(async () => {
  router.push(`/company/${route.params.id}/description`);
  await questionStatus.getQuestionStatus(Number(route.params.id));
});

const currentCompany = ref<CompanyInterface>();
provide("currentCompany", currentCompany);

const storageService = appContainer.resolve(StorageService);
const token = ref("");

async function getCurrentCompany() {
  return companyService.fetchCompanies().then((result) => {
    currentCompany.value = result.items.find(
      (company) => company.id === Number(route.params.id),
    );
  });
}
async function checkFrozenOnClick() {
  if (questionStatus.isFrozen) {
    return true;
  } else {
    toastService.error("Please Freeze the Questions to Access this Feature");
    return false;
  }
}
</script>

<template>
  <div class="d-flex align-items-center justify-content-between mb-4">
    <div class="breadcrumbs">
      <router-link to="/manage-companies" class="text-decoration-none"
        >Manage Companies</router-link
      >
      / {{ currentCompany?.name }}
    </div>
  </div>
  <div
    class="listing-company d-flex align-items-center w-100 p-3 flex-column text-center border-bottom"
    v-if="currentCompany"
  >
    <div class="img-company rounded overflow-hidden">
      <img
        v-if="currentCompany.logo_file"
        :src="`${currentCompany.logo_file}?token=${token}`"
        alt="Company Logo"
        class="w-100 h-100 object-fit-contain"
      />
      <i v-else class="bi bi-buildings-fill"></i>
    </div>
    <div class="company-info px-4 mx-2">
      <h4 class="ff-montserrat fs-medium text-capitalize mb-2">
        {{ currentCompany.name }}
      </h4>
      <p>
        {{ currentCompany.representative_email }}
      </p>
    </div>
  </div>

  <ul class="nav nav-pills my-5">
    <li class="nav-item">
      <router-link :to="`/company/${route.params.id}/description`" class="p-2"
        >Introduction</router-link
      >
    </li>

    <li class="nav-item">
      <router-link :to="`/company/${route.params.id}/questions`" class="p-2"
        >Add Questions</router-link
      >
    </li>

    <li
      class="nav-item"
      :class="{ disabled: !questionStatus.isFrozen }"
      @click="checkFrozenOnClick"
    >
      <router-link
        :to="`/company/${route.params.id}/manage-employees`"
        class="p-2"
        >Manage Employees</router-link
      >
    </li>
    <li class="nav-item">
      <router-link :to="`/company/${route.params.id}/reports`" class="p-2"
        >Reports</router-link
      >
    </li>
  </ul>
  <router-view></router-view>
</template>

<style scoped lang="scss">
@import "../../../theme/variables";
.nav-item {
  a {
    color: #000;
    text-decoration: none;
    border-bottom: 3px solid transparent;
    transition: ease-in 100ms;
    transform-origin: center;
  }
  .router-link-active {
    color: var(--bs-primary);
    border-color: var(--bs-primary);
  }
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;

    a {
      pointer-events: none;
    }
  }
}

// .listing-company {
//   background-color: rgb($blue, 0.2);
// }
.img-company {
  width: 100px;
  height: 100px;
  color: $blue;
}
</style>
