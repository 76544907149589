import { defineStore } from "pinia";
import { appContainer } from "../container";
import { ref } from "vue";
import { InvitedUserInterface } from "../interface/UserInterface";
import { StorageKeys, StorageService } from "../services/storage.service";

export const CurrentInvitationStore = defineStore("currentInvitation", () => {
  const storageService = appContainer.resolve(StorageService);

  const currentInvitation = ref<InvitedUserInterface | null>();
  async function getCurrentInvitation() {
    currentInvitation.value = await storageService.get<InvitedUserInterface>(
      StorageKeys.Current_Invitation,
    );
  }
  return { getCurrentInvitation, currentInvitation };
});
