import { defineStore } from "pinia";
import { ref } from "vue";
import { appContainer } from "../container";
import { ManageQuestionService } from "../services/manageQuestionService";

export const useIsFrozenStore = defineStore("frozenQuestion", () => {
  const isFrozen = ref(false);
  const questionService = appContainer.resolve(ManageQuestionService);

  const manageQuestionService = appContainer.resolve(ManageQuestionService);
  async function getQuestionStatus() {
    await questionService
      .getQuestionStatus()
      .then((result) => {
        if (result.frozen_on && !result.unfrozen_on) {
          isFrozen.value = true;
          return;
        }
        if (result.frozen_on > result.unfrozen_on) {
          isFrozen.value = true;
        } else {
          isFrozen.value = false;
        }
      })
      .catch(() => {
        isFrozen.value = false;
      });
  }
  async function toggleFrozenStatus(newStatus: boolean) {
    await manageQuestionService.freezeQuestions(newStatus);
    await getQuestionStatus();
  }

  return { isFrozen, getQuestionStatus, toggleFrozenStatus };
});
