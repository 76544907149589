<script setup lang="ts">
import { onBeforeMount, ref } from "vue";
import { PagingInterface } from "../../../interface/PaginationInterface";
import { InvitedUserInterface, Role } from "../../../interface/UserInterface";
import { appContainer } from "../../../container";
import { UserService } from "../../../services/userService";
import { LoadingService } from "../../../services/loadingService";
import { AuthService } from "../../../services/authService";
import UserListingCard from "../UserCardListing.vue";
import AddParticipantModal from "../modal/AddUserModal.vue";
import { CurrentInvitationStore } from "../../../stores/CurrentInvtationStore";

const paginatedData = ref<PagingInterface<InvitedUserInterface>>();
const invitedUsers = ref<InvitedUserInterface[]>([]);

const userService = appContainer.resolve(UserService);
const loadingService = appContainer.resolve(LoadingService);
const authService = appContainer.resolve(AuthService);
const showAddModal = ref(false);
const isLoading = ref(true);
const currentInvitationStore = CurrentInvitationStore();

onBeforeMount(async () => {
  await fetchUsers().then(() => (isLoading.value = false));
});

async function fetchUsers(
  pageConfig: { page?: number; offset?: number } = {
    page: 1,
    offset: 8,
  },
) {
  const loader = await loadingService.show();
  return userService
    .getInvitedUser(
      {
        invitationType: Role.Participants,
        invited_for: authService.user()?.id,
        all: true,
        companyId: currentInvitationStore.currentInvitation?.company_id,
      },
      pageConfig,
    )
    .then((result) => {
      paginatedData.value = result;
      invitedUsers.value = result.items;
    })
    .finally(() => loader.hide());
}

async function addUser() {
  fetchUsers();
  showAddModal.value = false;
}
</script>

<template>
  <template v-if="!isLoading">
    <div class="d-flex w-100 justify-content-between flex-wrap mb-4 gap-10">
      <h2 class="fw-medium fs-5 m-0">Manage Participants</h2>
      <div class="btn-wrapper d-flex align-items-center flex-wrap">
        <button
          class="btn btn-primary btn-sm text-white"
          @click="showAddModal = true"
        >
          Add Participant
        </button>
      </div>
    </div>
    <div v-if="invitedUsers.length" class="all-participant">
      <UserListingCard
        v-for="user in invitedUsers"
        :key="user.id"
        :user="user"
        :currentRole="Role.Admin"
        @invite="fetchUsers"
        @delete="fetchUsers"
        @edit="fetchUsers"
      />
    </div>

    <div
      class="no-result d-flex align-items-center justify-content-center text-opacity-50 text-danger vh-50"
      v-else
    >
      No Record Found
    </div>
    <AddParticipantModal
      v-if="showAddModal"
      @close="showAddModal = false"
      @add="addUser"
      :type="Role.Participants"
      :for="authService.user()!.id"
    />
  </template>
</template>
