<script setup lang="ts">
import { ref } from "vue";
import {
  QuestionInputTypes,
  QuestionInterface,
} from "../../interface/QuestionInterface";
import EditQuestionModal from "./EditQuestionModal.vue";
import DeleteQuestionModal from "./DeleteQuestionModal.vue";
import { useIsFrozenStore } from "../../stores/IsQuestionFrozenStore";

const props = defineProps<{
  question: QuestionInterface;
  index: number;
}>();

const isQuestionFrozen = useIsFrozenStore();

const emit = defineEmits(["edit", "delete"]);

const showEditModal = ref(false);
const showDeleteModal = ref(false);

function closeModal() {
  showEditModal.value = false;
}

function emitEdit() {
  showEditModal.value = false;
  emit("edit");
}
function emitDelete() {
  showDeleteModal.value = false;
  emit("delete");
}
</script>

<template>
  <div class="question-card d-flex align-items-start w-100">
    <div class="index fw-semibold">{{ props.index }}.</div>
    <div class="question-wrapper">
      <div class="question">
        {{ props.question.question_text }}
      </div>
      <div class="tags mt-2 d-flex align-items-center text-capitalize">
        <template
          v-if="props.question.input_type === QuestionInputTypes.Rating"
        >
          <span>{{ props.question.question_category }}</span
          >|
          <span>{{ props.question.question_subcategory }}</span>
          |
        </template>
        <span>{{ props.question.input_type }}</span>
      </div>
    </div>
    <div class="dropdown my-auto ms-auto">
      <button
        class="menu btn dropdown-toggle"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        :disabled="isQuestionFrozen.isFrozen"
      >
        <i class="bi bi-three-dots-vertical"></i>
      </button>
      <ul class="dropdown-menu p-1">
        <li class="d-flex align-items-center p-2" @click="showEditModal = true">
          <i class="bi bi-pencil-square me-3"></i>Edit
        </li>
        <li
          class="d-flex align-items-center p-2"
          @click="showDeleteModal = true"
        >
          <i class="bi bi-trash3 me-3"></i>Delete
        </li>
      </ul>
    </div>
  </div>
  <EditQuestionModal
    v-if="showEditModal"
    :question="props.question"
    @close="closeModal"
    @edit="emitEdit"
  />
  <DeleteQuestionModal
    v-if="showDeleteModal"
    :id="props.question.id"
    @close="showDeleteModal = false"
    @delete="emitDelete"
  />
</template>

<style lang="scss" scoped>
@import "../../theme/variables";

.gap-20 {
  gap: 20px;
}

.question-card {
  gap: 5px;
  border-bottom: 1px solid #d1d9e2;
  padding-top: 12px;
  padding-bottom: 12px;
  button {
    margin-top: auto;
    margin-bottom: auto;
    border: none !important;
    &::after {
      content: unset;
    }
  }
  .index {
    width: 25px;
  }
}
.tags {
  gap: 10px;
  font-size: 12px;
  color: rgba(108, 117, 125, 1);
}
.dropdown-menu {
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
  border: none;
  li {
    cursor: pointer;
    &:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.17);
    }
  }
}
</style>
