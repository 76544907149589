<script setup lang="ts">
import QuestionCardComponent from "./QuestionCardComponent.vue";
import AddQuestionModal from "./AddQuestionModal.vue";
import UploadCsvModal from "./UploadCsvModal.vue";
import { onMounted, ref } from "vue";
import { appContainer } from "../../container";
import { ManageQuestionService } from "../../services/manageQuestionService";
import { QuestionInterface } from "../../interface/QuestionInterface";
import { PagingInterface } from "../../interface/PaginationInterface";
import { LoadingService } from "../../services/loadingService";
import PaginationComponent from "../../components/PaginationComponent.vue";
import { useIsFrozenStore } from "../../stores/IsQuestionFrozenStore";

import FrozenConfirmationModal from "./FrozenConfirmationModal.vue";
import router from "../../routes";
import { ToastService } from "../../services/toastService";

onMounted(() => {
  isLoading.value = true;
  fetchQuestions({ page: 1, offset: 8 });
});

const isLoading = ref(false);

const manageQuestionService = appContainer.resolve(ManageQuestionService);
const loadingService = appContainer.resolve(LoadingService);
const toastService = appContainer.resolve(ToastService);

const questions = ref<QuestionInterface[]>([]);
const paginatedData = ref<PagingInterface<QuestionInterface>>();
const showAddModal = ref(false);
const showUploadModal = ref(false);
const questionFrozenStore = useIsFrozenStore();
const showFreezeModal = ref(false);

async function fetchQuestions(pageConfig: { page: number; offset: number }) {
  const loader = await loadingService.show();
  manageQuestionService
    .getQuestion(pageConfig)
    .then((paginatedResult) => {
      paginatedData.value = paginatedResult;
      questions.value = paginatedResult.items;
    })
    .finally(() => {
      loader.hide();
      isLoading.value = false;
    });
}
function showPreviewFeedback() {
  if (paginatedData.value?.totalItems) {
    router.push("/preview-feedback");
  } else {
    toastService.error("Please Add the Questions to Preview Feedback");
  }
}
async function add() {
  showAddModal.value = false;
  fetchQuestions({ page: 1, offset: 8 });
}

async function updateQuestion() {
  fetchQuestions({ page: 1, offset: 8 });
}

async function deleteQuestion() {
  fetchQuestions({ page: 1, offset: 8 });
}

async function uploadCsv() {
  showUploadModal.value = false;
  fetchQuestions({ page: 1, offset: 8 });
}

function updatePage(pageNumber: number) {
  if (pageNumber === paginatedData.value?.page) {
    return;
  }
  fetchQuestions({ page: pageNumber, offset: 8 });
}

function closeModal() {
  showAddModal.value = false;
}

async function freezeQuestions() {
  showFreezeModal.value = false;
}

function handleImportCSV() {
  if (!questionFrozenStore.isFrozen) {
    showUploadModal.value = true;
  } else {
    toastService.error("Please Unfreeze Questions to Import CSV");
  }
}

function handleAddQuestion() {
  if (!questionFrozenStore.isFrozen) {
    showAddModal.value = true;
  } else {
    toastService.error("Please Unfreeze Questions to Add Question");
  }
}
</script>

<template>
  <div class="d-flex w-100 justify-content-between flex-wrap mb-4 gap-10">
    <h2 class="fw-medium fs-4 m-0">Manage Questions</h2>
    <div class="btn-wrapper d-flex align-items-center flex-wrap">
      <button
        class="btn btn-primary btn-sm text-white btn-sm"
        @click="handleImportCSV"
        data-bs-toggle="tooltip"
        data-bs-title="Click to Import CSV file"
      >
        Import CSV
      </button>
      <button
        class="btn btn-primary btn-sm text-white"
        @click="handleAddQuestion"
      >
        Add Question
      </button>
      <button
        class="btn btn-primary btn-sm text-white"
        @click="showPreviewFeedback"
      >
        Preview Assessment Questions
      </button>
      <button
        class="btn btn-primary btn-sm text-white"
        @click="showFreezeModal = true"
      >
        <span v-if="questionFrozenStore.isFrozen">Unfreeze</span
        ><span v-else>Freeze</span> Questions
      </button>
    </div>
  </div>

  <template v-if="paginatedData?.items">
    <div class="all-question d-flex flex-column">
      <QuestionCardComponent
        v-for="(question, index) in questions"
        :key="question.id"
        :question="question"
        :index="
          paginatedData
            ? (paginatedData.page - 1) * paginatedData.offset + (index + 1)
            : 1
        "
        @edit="updateQuestion"
        @delete="deleteQuestion"
      />
    </div>
    <PaginationComponent
      :totalRows="paginatedData?.totalItems"
      :currentPage="paginatedData?.page"
      :perPage="paginatedData?.offset"
      @pageChange="updatePage"
    />
  </template>
  <template v-if="isLoading && paginatedData?.items">
    <p class="text-center mt-5">No Questions to view, Please add some</p>
  </template>

  <AddQuestionModal v-if="showAddModal" @close="closeModal" @add="add" />
  <UploadCsvModal
    v-if="showUploadModal"
    @close="showUploadModal = false"
    @upload="uploadCsv"
  />
  <FrozenConfirmationModal
    v-if="showFreezeModal"
    :status="questionFrozenStore.isFrozen ? 'unfreeze' : 'freeze'"
    @close="showFreezeModal = false"
    @yes="freezeQuestions"
  />
</template>
<style lang="scss" scoped>
.button-large {
  padding: 0.75rem 1rem;
}

.gap-10 {
  gap: 10px;
}
.btn-wrapper {
  gap: 1.5rem;
}
.all-question {
  height: calc(100% - 158px);
  max-height: 100%;
  overflow-y: auto;
}
.pagination-wrapper {
  button {
    border: none;
  }
  .page {
    button {
      width: 30px;
      height: 30px;
      display: block;
      padding: 0;
      &.currentPage {
        color: red;
      }
    }
  }
}
@media (max-width: 575px) {
  .all-question {
    height: calc(100% - 217px);
  }
  .btn-wrapper {
    gap: 0.75rem;
  }
}
</style>
