<script setup lang="ts">
import {
  InviteUserInterface,
  InvitedUserInterface,
} from "../../interface/UserInterface";
import UserListingCard from "./components/UserListingCard.vue";
// import router from "../../routes";

const props = defineProps<{ invitedUsers: InvitedUserInterface[] }>();
const emit = defineEmits(["delete", "edit", "invite"]);

// function showDetails(userID: number) {
//   router.push(`/manage-users/user-details/${userID}`);
// }
function emitInvite(id: number) {
  emit("invite", id);
}
function emitDelete(id: number) {
  emit("delete", id);
}
function emitEdit(user: InviteUserInterface, id: number) {
  emit("edit", user, id);
}
</script>
<template>
  <div class="all-users">
    <UserListingCard
      v-for="user in props.invitedUsers"
      :key="user.id"
      :user="user"
      @invite="emitInvite"
      @delete="emitDelete"
      @edit="emitEdit"
    />
  </div>
</template>
<style lang="scss" scoped></style>
