<template>
  <div class="wrap-pagination d-flex justify-content-end mt-0 mb-3">
    <ul class="pagination mb-0 mt-3">
      <li class="page-item">
        <a class="page-link" @click="goToPage(1)" :disabled="currentPage === 1"
          >First</a
        >
      </li>
      <li class="page-item">
        <a class="page-link" @click="previousPage" :disabled="currentPage === 1"
          >Prev</a
        >
      </li>
      <li class="page-item">
        <span
          class="page-link"
          v-if="
            (currentPage >= 4 && totalPages !== 4) ||
            (currentPage === 3 && totalPages === 5)
          "
          :disabled="true"
          >&#8230;</span
        >
      </li>
      <li
        class="page-item"
        v-for="pageNumber in visiblePages"
        :key="pageNumber"
      >
        <a
          class="page-link"
          @click="goToPage(pageNumber)"
          :class="{ active: currentPage === pageNumber }"
        >
          {{ pageNumber }}
        </a>
      </li>
      <li class="page-item">
        <span class="page-link" v-if="pageOptions" :disabled="true"
          >&#8230;</span
        >
      </li>
      <li class="page-item">
        <a
          class="page-link"
          @click="nextPage"
          :disabled="currentPage === totalPages || totalPages === 0"
          >Next</a
        >
      </li>
      <li class="page-item">
        <a
          class="page-link"
          @click="goToPage(totalPages)"
          :disabled="currentPage === totalPages || totalPages === 0"
          >Last</a
        >
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
//todo: make pagination visible tabs odd

import { ref, computed, watch } from "vue";

const props = defineProps({
  totalRows: {
    type: Number,
    required: true,
  },
  perPage: {
    type: Number,
    default: 10,
  },
  currentPage: {
    type: Number,
    default: 1,
  },
});

const emit = defineEmits(["pageChange"]);

const pageChange = "pageChange";
const visiblePageCount = ref(4);
const currentPage = ref(props.currentPage);

const totalPages = computed(() => Math.ceil(props.totalRows / props.perPage));

const visiblePages = computed(() => {
  let maxVisiblePages = Math.min(visiblePageCount.value, totalPages.value);
  let startPage: number;
  let endPage: number;
  if (currentPage.value >= 4) {
    maxVisiblePages = 3;
    startPage = currentPage.value - 1;
    endPage = currentPage.value + 1;
  } else {
    startPage = 1;
    endPage = Math.min(maxVisiblePages, totalPages.value);
  }
  if (currentPage.value === totalPages.value) {
    startPage = Math.max(currentPage.value - maxVisiblePages, 1);
    endPage = currentPage.value;
  }
  if (totalPages.value - currentPage.value < 3) {
    startPage = Math.max(totalPages.value - 3, 1);
    endPage = totalPages.value;
  }
  if (currentPage.value === 4 && totalPages.value === 4) {
    startPage = 1;
    endPage = totalPages.value;
  }
  if (totalPages.value === 0) {
    startPage = 1;
    endPage = 1;
  }
  return Array.from(
    { length: endPage - startPage + 1 },
    (_, i) => startPage + i,
  );
});

const pageOptions = computed(() => {
  let displayMorePageOption = true;
  if (
    currentPage.value === totalPages.value ||
    totalPages.value <= 4 ||
    totalPages.value - currentPage.value < 3
  ) {
    displayMorePageOption = false;
  }
  return displayMorePageOption;
});

const goToPage = (pageNumber: number) => {
  emit(pageChange, pageNumber);
};

const previousPage = () => {
  if (currentPage.value > 1) {
    emit(pageChange, currentPage.value - 1);
  }
};

const nextPage = () => {
  if (currentPage.value < totalPages.value) {
    emit(pageChange, currentPage.value + 1);
  }
};

watch(
  () => props.currentPage,
  (newPage) => {
    currentPage.value = newPage;
  },
);
</script>

<style scoped>
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}
button {
  margin: 0 5px;
  padding: 5px 10px;
  background-color: #e0e0e0;
  border: none;
  cursor: pointer;
}
button.active {
  background-color: #007bff;
  color: white;
}
.page-item {
  cursor: pointer;
}
@media (max-width: 575px) {
  .page-link {
    font-size: 14px;
    padding: 6px 8px;
  }
  .wrap-pagination {
    justify-content: center !important;
  }
}
</style>
