import { NavigationGuardWithThis } from "vue-router";
import { StorageKeys, StorageService } from "../services/storage.service";
import {
  InvitedUserInterface,
  RoleInterface,
} from "../interface/UserInterface";
import { ref } from "vue";
import { appContainer } from "../container";

export const ActiveRoleGaurd: NavigationGuardWithThis<undefined> = async () => {
  const storageService = appContainer.resolve(StorageService);

  const activeRole = ref<RoleInterface>();

  const currentInvitation = ref<InvitedUserInterface>();
  await storageService.get(StorageKeys.Active_Role).then((result) => {
    activeRole.value = result as RoleInterface;
  });
  await storageService.get(StorageKeys.Current_Invitation).then((result) => {
    currentInvitation.value = result as InvitedUserInterface;
  });

  if (currentInvitation.value && activeRole.value) {
    if (activeRole.value.role_name === "rater") {
      return { path: "instructions" };
    }
    return { path: "dashboard" };
  } else {
    return true;
  }
};
