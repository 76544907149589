<script setup lang="ts">
import { ref, watch, onMounted } from "vue";
import { CompanyInterface } from "../../../interface/CompanyInterface";
import ModalCopyCompany from "./ModalCopyCompany.vue";
import ModalEditCompany from "./ModalEditCompany.vue";
import ModalDeleteCompany from "./ModalDeleteCompany.vue";
import { appContainer } from "../../../container";
import { StorageKeys, StorageService } from "../../../services/storage.service";
import router from "../../../routes";

const props = defineProps<{
  receivedCompanyData: CompanyInterface[];
}>();

const listCompany = ref<CompanyInterface[]>(props.receivedCompanyData);
const storageService = appContainer.resolve(StorageService);
const token = ref("");

onMounted(async () => {
  token.value = (
    (await storageService.get(StorageKeys.TOKEN)) as any
  ).access_token;
});

watch(
  () => props.receivedCompanyData,
  (newVal) => {
    listCompany.value = newVal;
  },
);

const showEditModal = ref(false);
const showDeleteModal = ref(false);
const showCopyModal = ref(false);

// const emit = defineEmits(["delete", "edit"]);
const emit = defineEmits(["edit", "copy", "companyDeleted"]);

// Delete Modal
const deleteCompany = ref();

function emitDelete() {
  showDeleteModal.value = false;
  emit("companyDeleted");
}

function openDeleteModal(id: number) {
  deleteCompany.value = id;
  showDeleteModal.value = true;
}

// Edit Modal
const currentCompany = ref<CompanyInterface | null>(null);

function openEditModal(company: CompanyInterface) {
  currentCompany.value = company;
  showEditModal.value = true;
}

function emitEdit() {
  showEditModal.value = false;
  emit("edit");
}

const formatDate = (dateString: Date | string) => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  return date.toLocaleDateString("en-US", options);
};

function viewEmployee(id: number) {
  router.push(`company/${id}`);
}
function openCopyModal(company: CompanyInterface) {
  currentCompany.value = company;
  showCopyModal.value = true;
}

function copyCompany(id: number, companyName: string) {
  showCopyModal.value = false;
  emit("copy", id, companyName);
}
</script>

<template>
  <div
    v-for="company in listCompany"
    :key="company.id"
    class="listing-company d-flex align-items-center w-100 py-3 border-bottom"
    @click.prevent="viewEmployee(company.id)"
  >
    <div class="img-company rounded overflow-hidden">
      <img
        v-if="company.logo_file"
        :src="`${company.logo_file}?token=${token}`"
        alt="Company Logo"
        class="w-100 h-100"
      />
      <i v-else class="bi bi-buildings-fill"></i>
    </div>
    <div class="company-info px-4 mx-2">
      <h6 class="ff-montserrat fs-medium text-capitalize mb-0">
        {{ company.name }}
      </h6>
      <div class="date size-sm d-flex flex-column">
        <span class="email">
          {{ company.representative_email }}
        </span>
        <p class="mb-0">
          <span>Created on: </span>
          <strong class="fw-medium">{{
            formatDate(company.created_at)
          }}</strong>
        </p>
      </div>
    </div>
    <div class="group-btns my-auto ms-auto">
      <button title="View More" class="btn border-0 p-1">
        <i class="bi bi-arrow-up-right-square"></i>
      </button>
      <button
        class="border-0 bg-transparent ms-2"
        @click.stop="openCopyModal(company)"
      >
        <i class="bi bi-copy"></i>
      </button>
      <button
        class="border-0 bg-transparent ms-2"
        @click.stop="openEditModal(company)"
      >
        <i class="bi bi-pencil-square"></i>
      </button>
      <button
        class="border-0 bg-transparent ms-2"
        @click.stop="openDeleteModal(company.id)"
      >
        <i class="bi bi-trash3"></i>
      </button>
    </div>
  </div>
  <ModalCopyCompany
    v-if="showCopyModal"
    @close="showCopyModal = false"
    :company="currentCompany"
    @copy="copyCompany"
  />
  <ModalEditCompany
    v-if="showEditModal"
    :company="currentCompany"
    :token="token"
    @close="showEditModal = false"
    @edit="emitEdit"
  />
  <ModalDeleteCompany
    v-if="showDeleteModal"
    :id="deleteCompany"
    @close="showDeleteModal = false"
    @delete="emitDelete"
  />
</template>

<style scoped lang="scss">
@import "../../../theme/variables";

.img-company {
  padding: 5px;
  background-color: #eaf1fb;
  color: $blue;
  width: 64px;
  height: 64px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.size-sm {
  font-size: 12px;
}
.email {
  color: #999;
}
.group-btns {
  display: flex;
  align-items: center;

  button {
    font-size: 18px;
    &:nth-child(1):hover,
    &:nth-child(2):hover,
    &:nth-child(3):hover {
      color: #4a84dd;
    }

    &:nth-child(4):hover {
      color: red;
    }
  }
}
.listing-company {
  cursor: pointer;
}
</style>
