import { Container } from "inversify";
import { ApiCallService } from "./services/apiCall.service";
import axios, { Axios } from "axios";
import {
  API_REQUEST_INTERCEPTORS,
  API_RESPONSE_INTERCEPTORS,
} from "./injectableTokens";
import { AuthorizationTokenInterceptor } from "./interceptors/authorizationTokenInterceptor";
import { RequestInterceptor } from "./interface/interceptorInterface";
import { AuthService } from "./services/authService";

export const appContainer = new Container({ autoBindInjectable: true });

appContainer.bind<ApiCallService>(ApiCallService).toSelf().inSingletonScope();
appContainer.bind<AuthService>(AuthService).toSelf().inSingletonScope();
appContainer.bind<Storage>("Storage").toConstantValue(localStorage);
appContainer.bind<Axios>(Axios).toConstantValue(axios);
appContainer
  .bind<RequestInterceptor[]>(API_REQUEST_INTERCEPTORS)
  .toConstantValue([AuthorizationTokenInterceptor]);
appContainer.bind(API_RESPONSE_INTERCEPTORS).toConstantValue([]);
