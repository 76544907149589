<script setup lang="ts">
import { AxiosError } from "axios";
import { appContainer } from "../../../container";
import { LoadingService } from "../../../services/loadingService";
import { CompanyService } from "../../../services/manageCompanyService";
import { ToastService } from "../../../services/toastService";
import { ErrorResponse } from "../../../interface/ErrorInterface";
import { ref } from "vue";
import ValidationErrorComponent from "../../../components/ValidationErrorComponent.vue";

const props = defineProps<{
  id: number;
}>();
const emit = defineEmits(["close", "delete"]);

const validationErrors = ref<ErrorResponse>();

const companyService = appContainer.resolve(CompanyService);
const loadingService = appContainer.resolve(LoadingService);
const toastService = appContainer.resolve(ToastService);

async function deleteCompany() {
  const loader = await loadingService.show();
  companyService
    .deleteCompany(props.id)
    .then(() => {
      toastService.success("Company Deleted Successfully");
      emit("delete");
    })
    .catch((err) => {
      const error = err as AxiosError;
      const errorResponse = error.response?.data as any;
      validationErrors.value = {
        errors: {
          delete: [errorResponse.message],
        },
      };
    })
    .finally(() => loader.hide());
}
</script>

<template>
  <div class="modal-backdrop">
    <div class="modal" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body pt-5">
            <ValidationErrorComponent
              v-if="validationErrors"
              :validations="validationErrors"
            ></ValidationErrorComponent>
            <h5 class="ff-montserrat text-center m-0">
              Do you want to delete this company?
            </h5>
            <div class="d-flex justify-content-center p-4">
              <button
                type="button"
                class="btn btn-outline-primary mx-3"
                @click="emit('close')"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-danger text-white mx-3"
                @click="deleteCompany"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
button {
  &:hover {
    color: #fff;
  }
}
</style>
