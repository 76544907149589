import { NavigationGuardWithThis } from "vue-router";
import {
  InvitedUserInterface,
  RoleInterface,
} from "../interface/UserInterface";
import { ref } from "vue";
import { CurrentInvitationStore } from "../stores/CurrentInvtationStore";
import { ActiveRoleStore } from "../stores/ActiveRoleStore";

export const IsRoleRater: NavigationGuardWithThis<undefined> = async () => {
  const activeRole = ref<RoleInterface>();
  const currentInvitation = ref<InvitedUserInterface>();

  const currentInvitationStore = CurrentInvitationStore();
  const activeRoleStore = ActiveRoleStore();
  activeRole.value = activeRoleStore.activeRole as RoleInterface;
  currentInvitation.value =
    currentInvitationStore.currentInvitation as InvitedUserInterface;
  if (activeRole.value!.role_name === "rater") {
    return { path: "/instructions" };
  }
};

export const IsNotRoleRater: NavigationGuardWithThis<undefined> = async () => {
  const activeRole = ref<RoleInterface>();
  const currentInvitation = ref<InvitedUserInterface>();

  const currentInvitationStore = CurrentInvitationStore();
  const activeRoleStore = ActiveRoleStore();
  await activeRoleStore.getActiveRole();
  activeRole.value = activeRoleStore.activeRole as RoleInterface;
  await currentInvitationStore.getCurrentInvitation();
  currentInvitation.value =
    currentInvitationStore.currentInvitation as InvitedUserInterface;
  if (activeRole.value!.role_name != "rater") {
    return { path: "/dashboard" };
  }
};
