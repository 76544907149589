<script setup lang="ts">
import { onMounted } from "vue";
import { appContainer } from "../../container";
import { AuthService } from "../../services/authService";
import { CurrentInvitationStore } from "../../stores/CurrentInvtationStore";
import { Role } from "../../interface/UserInterface";

const authService = appContainer.resolve(AuthService);
const currentInvitationStore = CurrentInvitationStore();
onMounted(() => {
  currentInvitationStore.getCurrentInvitation();
});
</script>

<template>
  <div class="d-flex align-items-center justify-content-center w-100">
    <div class="flex-column flex-center">
      <template
        v-if="
          currentInvitationStore.currentInvitation?.invitation_type ===
          Role.Raters
        "
      >
        <h3 class="ff-montserrat text-body-tertiary fs-6">
          The assessment session for
          <strong>
            {{
              currentInvitationStore.currentInvitation?.invited_for_user
                ?.first_name
            }}
            {{
              currentInvitationStore.currentInvitation?.invited_for_user
                ?.last_name
            }}</strong
          >
          has expired.
        </h3>
        <h3 class="ff-montserrat mb-4 text-body-tertiary fs-6">
          You are no longer able to submit your assessment.
        </h3>
      </template>
      <template
        v-if="
          currentInvitationStore.currentInvitation?.invitation_type ===
          Role.Participants
        "
      >
        <h3 class="ff-montserrat text-body-tertiary fs-6">
          Your assessment session ended.
        </h3>
        <h3 class="ff-montserrat mb-4 text-body-tertiary fs-6">
          You will receive your report shortly.
        </h3>
      </template>
      <button class="btn btn-primary text-white" @click="authService.logout()">
        Logout
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">
button {
  padding: 5px 30px;
}
</style>
