<script setup lang="ts">
import { onMounted, ref } from "vue";
import { InvitedUserInterface, Role } from "../../interface/UserInterface";
import { appContainer } from "../../container";
import { UserService } from "../../services/userService";
import { useRoute } from "vue-router";
import UserListingCard from "../manage-users/components/UserListingCard.vue";

const userService = appContainer.resolve(UserService);

onMounted(async () => {
  await getCompanyParticipant();
});

const route = useRoute();

const participants = ref<InvitedUserInterface[]>();

async function getCompanyParticipant() {
  await userService
    .getInvitedUser({
      invitationType: Role.Participants,
      companyId: Number(route.params.id),
      feedback_completed: true,
      all: true,
    })
    .then((result) => (participants.value = result.items));
}
</script>
<template>
  <p class="fw-medium fs-5">Participants Listing</p>
  <UserListingCard
    v-for="participant of participants"
    :showDetail="false"
    :user="participant"
    :key="participant.id"
    :forReports="true"
  />
</template>
