<script setup lang="ts">
import { useRoute } from "vue-router";
import { ResponseInterFace } from "../../interface/QuestionInterface";
import DeleteCommentsModal from "../manage-reports/DeleteCommentsModal.vue";
import { onMounted, ref } from "vue";

const props = defineProps<{ response: ResponseInterFace; index: number }>();
const emits = defineEmits(["delete"]);

const route = useRoute();
onMounted(() => {
  console.log(route.params.id, route.params.participantId);
});
const showDeleteModal = ref(false);
function emitDelete() {
  showDeleteModal.value = false;
  emits("delete");
}
</script>

<template>
  <div
    class="response-wrapper py-4 border-bottom d-flex align-items-start gap-2"
  >
    {{ props.index + 1 }}.
    <span class="response ms-3">{{ props.response.paragraph_response }}</span>
    <button
      class="btn btn-danger btn-sm ms-auto border-none text-white my-auto"
      @click="showDeleteModal = true"
    >
      Delete
    </button>
  </div>
  <DeleteCommentsModal
    v-if="showDeleteModal"
    :invitationId="Number(route.params.participantId)"
    :responseId="props.response.id"
    @delete="emitDelete"
    @close="showDeleteModal = false"
  />
</template>

<style scoped lang="scss">
.response {
  max-width: 1200px;
}
</style>
