import { NavigationGuardWithThis } from "vue-router";
import { RoleInterface } from "../interface/UserInterface";
import { ref } from "vue";
import { ActiveRoleStore } from "../stores/ActiveRoleStore";

export const IsSuperAdmin: NavigationGuardWithThis<undefined> = async () => {
  const activeRole = ref<RoleInterface>();
  const activeRoleStore = ActiveRoleStore();
  activeRole.value = activeRoleStore.activeRole as RoleInterface;
  return activeRole.value!.role_name === "super_admin" ? true : "/404";
};
