<script setup lang="ts">
import { onMounted } from "vue";
import { appContainer } from "../container";
import { AuthService } from "../services/authService";
import { LoadingService } from "../services/loadingService";
import router from "../routes";

const authService = appContainer.resolve(AuthService);
const loadingService = appContainer.resolve(LoadingService);

onMounted(async () => {
  const urlParams = new URLSearchParams(window.location.search);
  const loader = await loadingService.show();
  const code = urlParams.get("code");
  if (code != null) {
    authService.login(code).then(() => {
      loader.hide();
      router.push("/dashboard");
    });
  }
});
</script>
<template>
  <div></div>
</template>
