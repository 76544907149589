import { injectable } from "inversify";
import { ToastProps, useToast } from "vue-toast-notification";

@injectable()
export class ToastService {
  private toast;
  constructor() {
    this.toast = useToast();
  }

  public success(message: string, options?: ToastProps) {
    this.toast.success(message, options);
  }

  public info(message: string, options?: ToastProps) {
    this.toast.error(message, options);
  }
  public warning(message: string, options?: ToastProps) {
    this.toast.error(message, options);
  }
  public error(message: string, options?: ToastProps) {
    this.toast.error(message, options);
  }
}
