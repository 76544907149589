<script setup lang="ts">
import { onMounted, ref } from "vue";
import { appContainer } from "../../container";
import { LoadingService } from "../../services/loadingService";
import { ReportService } from "../../services/reportService";
import { ResponseInterFace } from "../../interface/QuestionInterface";
import ResponseCard from "./ResponseCard.vue";
import SaveResponsesModal from "./SaveResponsesModal.vue";
import router from "../../routes";
import { useRoute } from "vue-router";
import { InvitedUserInterface, Role } from "../../interface/UserInterface";
import { UserService } from "../../services/userService";

const reportService = appContainer.resolve(ReportService);
const loadingService = appContainer.resolve(LoadingService);
const userService = appContainer.resolve(UserService);

const route = useRoute();

const responses = ref<ResponseInterFace[]>();
const isLoading = ref(true);

const showComfirmModal = ref(false);
onMounted(async () => {
  const loader = await loadingService.show();

  await getResponses().then(() => {
    setTimeout(() => {
      loader.hide();
      isLoading.value = false;
    }, 500);
  });
});

async function getResponses() {
  const loader = await loadingService.show();
  reportService
    .getResponses(Number(route.params.participantId))
    .then(
      (result) =>
        (responses.value = result.question_responses.filter(
          (response) => response.paragraph_response != null,
        )),
    )
    .finally(() => loader.hide());
}

async function handleSave(result: InvitedUserInterface) {
  showComfirmModal.value = false;
  const admin = await userService.getInvitedUser({
    invited_user: result.invited_for_user?.id,
    invitationType: Role.Admin,
  });
  if (Number(route.params.id))
    router.push(`/company/${Number(route.params.id)}/reports`);
  else router.push(`/manage-reports/${admin.items[0].id}/participant`);
}
</script>
<template>
  <template v-if="isLoading"></template>
  <template v-else>
    <div class="d-flex align-items-center justify-content-between gap-2 mb-4">
      <h2 class="fw-medium fs-4">Review Comments</h2>
      <button
        class="btn btn-primary text-white"
        @click="showComfirmModal = true"
      >
        Save Comments
      </button>
    </div>

    <p class="fw-medium">
      <span class="fw-bold">Note : </span>Admins are responsible for ensuring a
      respectful and constructive environment by monitoring comments. When
      reviewing comments, any content containing vulgar or profane language
      should be deleted immediately without warning. Once deleted, the decision
      is final, and the comment will not be restored.
    </p>

    <ResponseCard
      v-for="(response, index) in responses"
      :key="index"
      :response="response"
      :index="index"
      @delete="getResponses"
    />
    <div
      class="no-result d-flex align-items-center justify-content-center text-opacity-50 text-danger h-50"
      v-if="responses?.length === 0"
    >
      No Records Found
    </div>
  </template>
  <SaveResponsesModal
    v-if="showComfirmModal"
    :invitationId="Number(route.params.participantId)"
    @close="showComfirmModal = false"
    @save="handleSave"
  />
</template>
